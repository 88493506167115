import React, {useContext} from 'react';
import {NavLink, Link, useHistory} from 'react-router-dom';

// Hooks
import {useAuth} from "../../auth/use-auth.js";

// Context
import {UserContext} from '../../context/authContext';

function AuthButton() {
	
	const auth = useAuth();
	let history = useHistory();
	const userContext = useContext(UserContext);
 	const user = userContext;
 	
	return auth.user ? (
	<ul className="navbar-nav me-auto nav-pills mb-md-0">
		{/* 
	 	<li className="nav-item me-md-2"><Link to="/dashboard" className="nav-link">Go to Dashboard</Link></li>
	 	*/}
	 	<li className="nav-item dropdown me-md-2">
			<button className="nav-link dropdown-toggle" 
				type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" 
				aria-haspopup="true" aria-expanded="false">
			{user.firstName}</button>
			<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
				<li><Link to="/auth/account" className="dropdown-item">Account</Link></li>
				<li><button type="button" className="btn-link dropdown-item" onClick={() => {
					auth.signout( () => history.push("/") );
				}} >Sign out</button></li>
			</ul>
	 	</li>
	</ul>
	) : (
	<ul className="navbar-nav me-auto nav-pills mb-md-0">
		<li><Link to="/auth/login" className="nav-link">Login</Link></li>
	</ul>
)}

function Mast() {
	return (
	<header className="navbar navbar-dark navbar-expand-sm">
		<div className="container-fluid">
			<Link to="/dashboard" className="navbar-brand"></Link>
			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
     			 <span className="navbar-toggler-icon"></span>
    		</button>
    		<div id="navbarSupportedContent" className="collapse navbar-collapse">
				<ul className="navbar-nav me-auto mb-md-0">
				
					<li className="nav-item me-md-2">
						<NavLink exact to="/dashboard" activeClassName="active" className="nav-link">Dashboard</NavLink>
					</li>
				
					<li className="nav-item me-md-2">
						<NavLink exact to="/products" activeClassName="active" className="nav-link">Products</NavLink>
					</li>
				
					{/* 
					<li className="nav-item me-md-2">
						<NavLink to="/events"  activeClassName="active" className="nav-link">Spatial 3D</NavLink>
					</li>
					*/}
					{/* 
					<li className="nav-item me-md-2">
						<NavLink to="/events" activeClassName="active" className="nav-link">Events</NavLink>
					</li>
					*/}
					
					<li className="nav-item me-md-2">
						<NavLink to="/about" activeClassName="active" className="nav-link">About Us</NavLink>
					</li>
					
					{/* 
					<li className="nav-item me-md-2">
						<NavLink to="/playgrounds" activeClassName="active" className="nav-link">Playgrounds</NavLink>
					</li>
					 <li className="nav-item me-md-2">
						<NavLink to="/docs" activeClassName="active" className="nav-link">Docs</NavLink>
					</li>
					*/}
					
				</ul>
				<div className="d-md-flex">
					<AuthButton />
				</div>
			</div>
		</div>
	</header>
	);
}
export default Mast;
