import React from 'react';

// Components
import NavPlaygrounds from '../components/navs/NavPlaygrounds';

// Components
import Counter from '../components/controls/Counter'

function Page() {

	
	return (
	<main className="main-container container mt-3">
		<NavPlaygrounds />
		<Counter />
	</main>
  );
}

export default Page;
