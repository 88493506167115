import React, {useState, useContext} from 'react';
import {Link } from 'react-router-dom';

// Components
import Events from './events/components/Events';

// Context
import {UserContext} from './context/authContext';

function Page() {

	const user = useContext(UserContext);
	const [properties, setProperties] = useState(user.properties[0].id);
	
	return (
	<>
	<div className="container-fluid hero">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<p>Argus <span>Extended Reality</span></p>
				</div>
			</div>
		</div>
	</div>
	
	<main className="main-container container mt-3">
	
		<h1 className="mb-3">Dashboard</h1>
		
		<div className="row mb-3">
			<div className="col mt-2">
				<div className="row">
					<div className="col-sm-10">
						<select id="properties" onChange={(e) => setProperties(e.target.value)} className="form-select" style={{width: "200px"}}>
							{(user) &&
								user.properties.map((value, i) => (
									<option key={i} value={value.id}>{value.name}</option>
								))
							}
						</select>
					</div>
				</div>
			</div>
			<div className="col mt-2">
				<div className="row">
					<div className="col-sm-10">
						<select id="sort" className="form-select" style={{width: "200px"}}>
							<option>All events</option>
							<option value="1">Your events</option>
						</select>
					</div>
				</div>
			</div>
			<div className="col mt-2 align-self-end">
				<Link className="btn btn-primary text-nowrap" to={`/event-create/${properties}`}>+ Event</Link>
			</div>
		</div>
		{(user) &&
			<Events propertyId={properties} />
		}
	</main>
	</>
	);
}
export default Page;
