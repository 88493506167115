import * as THREE from 'three';

export function usePrimitive() {

	var addPrimitive = (model, group, scene) => {
	
		var posX = model.pos.x || 0;
		var posY = model.pos.y || 0;
		var posZ = model.pos.z || 0;
	
		var rotateX = model.rotate.x || 0;
		var rotateY = model.rotate.y || 0;
		var rotateZ = model.rotate.z || 0;
	
		var scaleX = model.scale.x || 1;
		var scaleY = model.scale.y || 1;
		var scaleZ = model.scale.z || 1;

		var color = "#ffffff";
	
		if (model.nodes) {
			color = model.nodes[0].color || "#ffffff";
		}
		var colorReset = new THREE.MeshLambertMaterial({color: color});;
		var geometry;
	
		switch(model.type) {
		
			case "sphere":
		
			geometry = new THREE.SphereGeometry( .5, 32, 16 );
			break;
		
			default:
			geometry = new THREE.BoxGeometry( scaleX, scaleY, scaleZ );
		}
	
		var mesh = new THREE.Mesh(geometry, colorReset);
		mesh.name = model.name;
		mesh.position.set(posX, posY, posZ);
		mesh.rotation.set(rotateX, rotateY, rotateZ);
	
		if (model.name === "sphere") {
			mesh.scale.set(scaleX, scaleY, scaleZ);
		}
		
		group.add(mesh)
		scene.add(group);
	}
	
	return {addPrimitive};
}