import { useState } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';

// Hooks & Components
import { useAuth } from "./use-auth.js";
import Card from '../components/cards/Card';

const Login = () => {
		
	let [email, setEmail] = useState('');
	let [password, setPassword] = useState('');
	
	let history = useHistory();
	let location = useLocation();
	let auth = useAuth();
	
	let {from} = location.state || {from: {pathname: "/" }};
	let login = (e) => {
	
		e.preventDefault();
	
		if ((email === "") || (password === "")) {
			auth.setNotification('All fields are required')
			setTimeout(() => {
				auth.setNotification('')
			}, 6000)
			return null;
		}
		
		auth.signin(email, password)
			.then(() => {
				history.replace(from);
			});
			
		setEmail('')
		setPassword('')
	};

	return (
	<main className="main-container container">
		<div className="row mt-4 justify-content-center">
			<div className="col-md-6">
				<form>
				<Card id="card-login" heading="Login" link="">
					{!(auth.notification ==="") &&
					<div className="alert alert-danger" role="alert">
					  {auth.notification}
					</div>
					}
					<p>You must log in to view the page. {/*`at ${from.pathname}`*/}</p>
					<div className="mb-2">
						<input id="email" autoComplete="email" type="text" className="form-control" value={email} placeholder="Email" onChange={({target}) => setEmail(target.value)} />
					</div> 
					<div className="mb-2">
						<input id="password" autoComplete="current-password" type="password" className="form-control" value={password} placeholder="Password" onChange={({target}) => setPassword(target.value)} />
					</div> 
					<div className="mt-5 d-flex justify-content-end">
						<button onClick={login} className="btn btn-primary mr-2">Sign in</button>
					</div>
					<div className="mt-3 d-flex justify-content-end">
						<Link to="/auth/register">Register</Link>
					</div>
				</Card>
				</form>
			</div>
		</div>
	</main>
	)
}
export default Login