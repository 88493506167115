import { useState } from 'react'; 
import {Link, useHistory, useLocation} from 'react-router-dom';

// Hooks & Components
import { useAuth } from "./use-auth.js";
import Card from '../components/cards/Card';

const Register = () => {
	
	let [email, setEmail] = useState('');
	let [password, setPassword] = useState('');
	let [passConf, setPassConf] = useState('');
	
	let history = useHistory();
	let location = useLocation();
	let auth = useAuth();
	
	let {from} = location.state || {from: {pathname: "/" }};
	let register = (e) => {
	
		e.preventDefault();
	
		if ((email === "") || (password === "")) {
			auth.setNotification('All fields are required')
			setTimeout(() => {
				auth.setNotification('')
			}, 6000)
			return null;
		}
	
		if (password !== passConf) {
			auth.setNotification('Password and password confirmation does not match')
			setTimeout(() => {
				auth.setNotification('')
			}, 6000)
			return null;
		}
	
		auth.signup(email, password, passConf)
			.then(() => {
				history.replace(from);
			});
		
		setEmail('')
		setPassword('')
		setPassConf('')
	};
	
	return (
	<main className="main-container container">
		<div className="row mt-4 justify-content-center">
			<div className="col-md-6">
				<form>
				<Card id="card-login" heading="Create Account" link="">
					{!(auth.notification ==="") &&
					<div className="alert alert-danger" role="alert">
					  {auth.notification}
					</div>
					}
					<div className="mb-2">
						<input id="email" type="text" className="form-control" value={email} placeholder="Email" onChange={({target}) => setEmail(target.value)} /> 
					</div>
					<div className="mb-2">
						<input id="password" type="password" className="form-control" value={password} placeholder="Password" onChange={({target}) => setPassword(target.value)} />
					</div> 
					<div className="mb-2">
						<input id="password-conf" type="password" className="form-control" value={passConf} placeholder="Confirm password" onChange={({target}) => setPassConf(target.value)} />
					</div>
					<div className="mt-3 d-flex justify-content-end">
						<button onClick={register} className="btn btn-primary mr-2">Sign in</button>
					</div>
					<div className="mt-3 d-flex justify-content-end">
						<Link to="/auth/login">Login</Link>
					</div>
				</Card>
				</form>
			</div>
		</div>
	</main>
  )
}
export default Register