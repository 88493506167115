import React from 'react';

const Material = (props) => {

	var colorChange = (e) => {
	
		props.setNodes(
			prevState => {
			
			 	let color = e.target.value;
				let newArray = prevState.map(element => element.name === props.node.name ? {...element, color : color} : element);
				return newArray;
			}
		);
	}
	
	/*
	var textureChange = (e) => {
	
		props.setNodes(
			prevState => {
			
			 	let texture = e.target.value;
				let newArray = prevState.map(element => element.name == props.node.name ? {...element, texture : e.target.value} : element);
				
				//console.log(newArray)
				return newArray;
			}
		);
	}
	*/
	
	return (
	<>
	{/* <label htmlFor={`color props.id`} className="col-6 mt-2 form-label">Colors</label> */}
	<div className="my-3">
		<select id={`color props.id`} value={props.node.color} onChange={(e) => {colorChange(e)}} className="form-select form-select-sm">
			<option value="">Choose Color</option>
			{props.node.options && props.node.options.map((obj, i) => 
				<option value={obj.hex} key={i}>{obj.name}</option>
			)}
		</select>
	</div>
	{/*
	<div className="my-3">
		<select value={props.node.texture} onChange={(e) => {textureChange(e)}} className="form-select form-select-sm">
			<option value="none">Choose Texture</option>
			<option value="/img/textures/crate.gif">Crate</option>
		</select>
	</div>
	 */}
	<div className="my-3 row">
		<div className="col-6">
			<input type="color" className="form-control form-control-color" id={props.id} onChange={(e) => {colorChange(e)}} value={props.node.color} title="Choose color" />
		</div>
	</div>
	</>
	)
}
export default Material;
