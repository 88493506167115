import {Link} from 'react-router-dom';
import Card from './components/cards/Card';

function Home() {
	return (
	<>
	<div className="container-fluid hero">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<p>Argus <span>Extended Reality</span></p>
				</div>
			</div>
		</div>
	</div>
	<main className="main-container container">
		<div className="row mt-4 justify-content-center">
			<div className="col-md-6">
			
				<p><span className="argus-mark">Argus <abbr title="Extended Reality">XR</abbr> <sup>TM</sup></span> is a software company, providing extended reality solutions to enterprises.</p>

				<p>
				Industry needs change as clients and users demand evolves. Listening to our clients' needs and supporting them with effective tools is at the core of our vision as we develop long lasting partnerships and grow with our clients.   
				</p>
			
				<p>Our solutions are ...</p>
				
				<ul>
					<li>Cross-platform and cross-browser</li>
					<li>Usable and accessible</li>
					<li>Customizable, offering solutions to different industries and particular needs to fit each particular need.</li>
				</ul>
			</div>
			<div className="col-md-6">
			
				<h2>Products</h2>
				
				<Link className="block" to="/dashboard">
				<Card
					id="spatial-designer" heading="Spatial 3D" img="/img/products/spatial-3D.png">
					<p>Spatial 3D is a browser base tool for visualizing event spaces. Its easy to use <abbr title="User Interface">UI</abbr> is ideal for sale teams.</p>
				</Card>	
				</Link>
			</div>
		</div>
	</main>
	</>
  );
}
export default Home;
