import {useRef} from 'react';

// Hooks
import {useLoader} from './model-load.js';
import {usePrimitive} from './add-primitive.js';

export function useModel() {

	const loader = useRef(useLoader());
	const loaderPrime = useRef(usePrimitive());
	
	const addModel = (model, obj, group, scene) => {
		
		switch(model) {
			case "table60in":

				obj.url = '/models/table-60in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table66in":

				obj.url = '/models/table-66in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table72in":

				obj.url = '/models/table-72in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table60inChiavari":

				obj.url = '/models/table-chiavari-60in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table66inChiavari":

				obj.url = '/models/table-chiavari-66in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table72inChiavari":

				obj.url = '/models/table-chiavari-72in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table30inCocktail":

				obj.url = '/models/table-30in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table18inMeeting":

				obj.url = '/models/table-meeting-60-18in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table30inMeeting":

				obj.url = '/models/table-meeting-60-30in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table18inBanquet":

				obj.url = '/models/table-banquet-60-18in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "table30inBanquet":

				obj.url = '/models/table-banquet-60-30in/table.gltf';
				loader.current.addTable(obj, group, scene);
			break;
			
			case "chairLancaster":

				obj.url = '/models/chair-lancaster/chair.gltf';
				loader.current.addChair(obj, group, scene);
			break;
			
			case "chairChiavari":

				obj.url = '/models/chair-chiavari/chair.gltf';
				loader.current.addChair(obj, group, scene);
			break;		
			
			case "portableBar":

				obj.url = '/models/portableBar/bar.gltf';
				loader.current.addPortableBar(obj, group, scene);
			break;	
			
			case "videoScreen":

				obj.url = '/models/videoScreen/screen.gltf';
				loader.current.addVideoScreen(obj, group, scene);
			break;
			
			case "stageUnit":

				obj.url = '/models/stageUnit/unit.gltf';
				loader.current.addStageUnit(obj, group, scene);
			break;
			
			case "podium":

				obj.url = '/models/podium/podium.gltf';
				loader.current.addPodium(obj, group, scene);
			break;
			
			case "danceFloorUnit":
			
				loaderPrime.current.addPrimitive(obj, group, scene);
			break;
			
			case "cube":
			
				loaderPrime.current.addPrimitive(obj, group, scene);
			break;
			
			case "sphere":
				
				loaderPrime.current.addPrimitive(obj, group, scene);
			break;	
			default: 

				obj.url = '';
		}
	};
	
	return {addModel};
}