
/* NOTE: For testing */

import React, {useState, useEffect} from 'react'; // useEffect , {useState, useEffect, useRef}

const Counter = (props) => {

	
	const [count, setCount] = useState(0);
	
	
	/* Runs each time component rerenders */
	//console.log("before mount")
	
	/* Run on mount and update */
	useEffect(() => {
	
		console.log("on mount & update");
		
		return () => {
		
			console.log("unmount");
		};
	})
	
	
	/* Runs on mount
	useEffect(() => {
	
		console.log("on mount")
	}, [])
	*/
	
	
	return (
	<div>
      <p>You clicked {count} times</p>
   <button onClick={() => setCount(count + 1)}>Click me</button> 
      {/*  <button onClick={() => props.setCount(props.count + 1)}>Click me</button> */}
    </div>
	)
}
export default Counter;
