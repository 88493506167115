import React, { useState, useEffect, useContext, createContext } from "react";

// Firebase
import '../config/fire-config';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';

// Context
const authContext = createContext();
export const useAuth = () => { return useContext(authContext);};

export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useProvideAuth() {
	
	const [user, setUser] = useState(null);
	const [email, setEmail] = useState(null);
	const [notification, setNotification] = useState('');
	const auth = getAuth();
	
	const signin = (email, password) => {
		
		return signInWithEmailAndPassword(auth, email, password)
			.then((reponse) => {
				setUser(reponse.user.uid);
				return reponse.user.uid;
			})
			.catch((error) => {
				console.log(error.code, error.message)
				setNotification(error.message)
				setTimeout(() => {
					setNotification('')
				}, 6000);
			});  
	};
	
	const signup = (email, password, passConf) => {
    	 
		return createUserWithEmailAndPassword(auth, email, password)
			.then((reponse) => {
				setUser(reponse.user.uid);
				return reponse.user.uid
			})
			.catch((error) => {
				console.log(error.code, error.message)
				setNotification(error.message)
				setTimeout(() => {
					setNotification('')
				}, 6000);
			});
  	};
  	
  
  	const signout = () => {
  	
		signOut(auth).then(() => {
		  setUser(false);
		})
	};
	
	const sendPasswordResetEmail = (email) => {
		setUser("sendPasswordResetEmail");
	};

	const confirmPasswordReset = (code, password) => {
		setUser("confirmPasswordReset");
	};

	useEffect(() => {
		
		const auth = getAuth();
		
		const unsubscribe = onAuthStateChanged(auth, (user) => {
		  if (user) {
			setUser(user.uid);
			setEmail(auth.currentUser.email);
		  } else {
			setUser(false);
			setEmail(false);
		  }
		});
		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, []);

	return {user, email, notification, setNotification, signin, signup, signout, sendPasswordResetEmail, confirmPasswordReset};
} 