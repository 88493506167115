function Page() {
  return (
	<main className="main-container container">
		<div className="row mt-4 justify-content-center">
			<div className="col-md-8">
				<h1>Vendors</h1>
				<ul>
					<li><a href="https://www.forbesindustries.com">Forbes Industries</a>:
					Forbes Industries is a U.S. based manufacturer with over 100 years of experience in the design and manufacturing of mobile carts and static products. Most products are manufactured in our state of the art facility in California and ship worldwide. We apply our core values to every product produced: engineered with safety, durability, functionality and attractive appearance.
					</li>
					<li><a href="https://www.webstaurantstore.com/">WebstaurantStore</a>: 
					Since our beginnings in 2004, WebstaurantStore has succeeded in creating and fostering an innovative, easy-to-use website to meet the purchasing needs of food service professionals internationally. Developers, programmers, content writers, technical support staff, purchasers, and warehouse employees work together to ensure we provide the best in price, service, and industry knowledge to our customers. Get to know our staff by checking out our blog for up-to-date tips, industry news, new product information, and more!
					</li>
				</ul>	
			</div>
		</div>	
	</main>
  );
}

export default Page;
