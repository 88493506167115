import React, {useState, useEffect, useRef} from 'react';
import * as THREE from 'three';
import RoomJson from '../json/Room.js';

const Margin = (props) => {

 	const [checked, setChecked] = useState(false);
 	const checkedRef = useRef(checked);
 	const controls = useRef(null);
 	const roomRef = useRef(null);
 	
	useEffect(() => {
	
		if (props.sceneInfo) {
			roomRef.current = RoomJson.[props.propertyId].find(obj => obj.name === props.sceneInfo.room);
		}
 	
 		const widthX = roomRef.current.width.x;
		const y = .3;
		const widthZ = roomRef.current.width.z;
		const xPos = (widthX/2);
		const zPos = (widthZ/2);
		const material = new THREE.LineBasicMaterial({color: 0xff0000});
		const points = [];
		const wall = 0;
		
		points.push( new THREE.Vector3( -xPos, y, -zPos ));
		points.push( new THREE.Vector3( (xPos + wall), y, -zPos ));
		points.push( new THREE.Vector3( (xPos + wall), y, (zPos + wall) ));
		points.push( new THREE.Vector3( -xPos, y, (zPos + wall) ));
		points.push( new THREE.Vector3( -xPos, y, -zPos ));

		const geometry = new THREE.BufferGeometry().setFromPoints(points);
		const marginGrid = new THREE.Line(geometry, material);
		const axesHelper = new THREE.AxesHelper(1);
		
		props.sceneRef.add(marginGrid);
		props.sceneRef.add(axesHelper);
		axesHelper.position.set(0, 4, 0);
		
		marginGrid.visible = false;
		axesHelper.visible = false;
		
		var handleChange = () => {
		
			checkedRef.current = !checkedRef.current;
		
			if (checkedRef.current) {
					marginGrid.visible = true;
					axesHelper.visible = true;
			} else {
				marginGrid.visible = false;
				axesHelper.visible = false;
			}
		}
	
		controls.current = {handleChange};	
	}, [props.sceneInfo, props.sceneRef, props.propertyId])
 	
	return (
	<div className="form-check">
		<input className="form-check-input" onChange={() => {controls.current.handleChange(); setChecked(!checked);}} 
		type="checkbox" id="visibilityCheck" checked={checked} />
		<label className="form-check-label" htmlFor="visibilityCheck">Margin Grid</label>
	</div>
	)
}
export default Margin;