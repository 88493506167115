import React, {useState, useEffect, useContext, useRef} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import './css/App.scss';

// Firebase
import fire from './config/fire-config'
import {getFirestore, getDoc, doc} from 'firebase/firestore';
import {getAuth, onAuthStateChanged} from 'firebase/auth';

// Components
import Mast from './components/mast/Mast';
import MastScene from './components/mast/MastScene';

/*** Pages ***/
import Products from './Products';
import Event from './events/Event';
import EventAdd from './events/EventAdd';
import EventSuccess from './events/EventSuccess';
import SceneAdd from './scenes/SceneAdd';
import Dashboard from './Dashboard';
import Scene from './scenes/Scene';
import SceneSuccess from './scenes/SceneSuccess';
import Room from './design/Room';
import About from './About';
import Vendors from './Vendors';

// Playgrounds
import Playground from './playgrounds/Playground';
import PlaygroundCube from './playgrounds/PlaygroundCube';
import PlaygroundPromise from './playgrounds/PlaygroundPromise';
import PlaygroundPromise2 from './playgrounds/PlaygroundPromise2';
import PlaygroundCounter from './playgrounds/PlaygroundCounter';

// Docs
import API from './docs/API';
import Specs from './docs/Specs';

import Login from './auth/Login'; 
import Register from './auth/Register';
import Account from './auth/Account';
// import PanelControls from './components/PanelControls';
import { useAuth, ProvideAuth } from "./auth/use-auth.js";

// Context
import {UserContext, Theme, authContext} from './context/authContext';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
	
	const db = getFirestore(fire);
	
	const authRef = useRef(getAuth());
	
	const defaultAuthUserContext = useContext(authContext);
 	const [authUser, setAuthUser] = useState(defaultAuthUserContext);
 	
 	const userContext = useContext(UserContext);
 	const [user, setUser] = useState(userContext);
 	
 	const defaultThemeContext = useContext(Theme);
 	const [theme, setTheme] = useState(defaultThemeContext);
	
	useEffect(() => {
	
		onAuthStateChanged(authRef.current, (user) => {
		  if (user) {
			
			async function getUser(db) {

				const col = doc(db, 'users', authRef.current.currentUser.uid);
				const snapshot = await getDoc(col);
			
				if (snapshot.exists()) {
					setUser({id: snapshot.id, ...snapshot.data()})
				}
			}
			
			setAuthUser(authRef.current)	
			getUser(db);
		  } else {
			// User is signed out
			// ...
		  }
		});
		
  	}, [db, authRef.current.currentUser])

	return (
	<ProvideAuth>
	<authContext.Provider value={[authUser, setAuthUser]}>
	<UserContext.Provider value={user}> {/* [user, setUser] */}
	<Theme.Provider value={[theme, setTheme]}> 
	
	<Router>
		<div className={`App theme-${theme}`}> 
			
			{/* 
			<p>AuthRef: {authRef.current.currentUser && authRef.current.currentUser.uid }</p>
			<p>authUser: {authUser && authUser.currentUser.uid }</p>
			*/}
			
			{/*  <PanelControls /> */}
			
			<Switch>
				<Route path="/auth/login">
					<Mast />
					<Login />
				</Route>
				
				<Route path="/auth/register">
					<Mast />
					<Register />
				</Route>
				
				<PrivateRoute path="/auth/account">
					<Mast />
					<Account />
				</PrivateRoute>
				
				<PrivateRoute exact path="/">
					<Mast />
					<Dashboard />
				</PrivateRoute>
				
				<PrivateRoute path="/products">
					<Mast />
					<Products />
				</PrivateRoute>
				
				<PrivateRoute path="/dashboard">
					<Mast />
					<Dashboard />
				</PrivateRoute>
				
				<PrivateRoute path="/event-create/:propertyId">
					<Mast />
					<EventAdd />
				</PrivateRoute>
				
				<PrivateRoute path="/event-success">
					<Mast />
					<EventSuccess />
				</PrivateRoute>
				
				<PrivateRoute path="/event/:propertyId/:eventId">
					<Mast />
					<Event />
				</PrivateRoute>
				
				<PrivateRoute path="/scene/:propertyId/:eventId/:sceneId">
					<Mast />
					<Scene />
				</PrivateRoute>
				
				<PrivateRoute path="/scene-add/:propertyId/:eventId">
					<Mast />
					<SceneAdd />
				</PrivateRoute>
				
				<PrivateRoute path="/scene-success">
					<Mast />
					<SceneSuccess />
				</PrivateRoute>
				
				<PrivateRoute exact path="/design">
					<Room />
				</PrivateRoute>
				
				<PrivateRoute path="/design/room/:propertyId/:eventId/:sceneId/:vr/:orbit">
					<MastScene />
					<Room />
				</PrivateRoute>
				
				<PrivateRoute path="/about">
					<Mast />
					<About />
				</PrivateRoute>
				
				<PrivateRoute path="/vendors">
					<Mast />
					<Vendors />
				</PrivateRoute>
				
				{/* Playground */}
				
				<PrivateRoute exact path="/playgrounds">
					<Mast />
					<Playground />
				</PrivateRoute>
				
				<PrivateRoute exact path="/playgrounds/promise">
					<Mast />
					<PlaygroundPromise />
				</PrivateRoute>
				
				<PrivateRoute exact path="/playgrounds/events">
					<Mast />
					<PlaygroundPromise2 />
				</PrivateRoute>
				
				<PrivateRoute exact path="/playgrounds/cube">
					<Mast />
					<PlaygroundCube />
				</PrivateRoute>
				
				<PrivateRoute exact path="/playgrounds/counter">
					<Mast />
					<PlaygroundCounter />
				</PrivateRoute>
				
				{/* Docs */}
				
				<PrivateRoute exact path="/docs">
					<Mast />
					<API />
				</PrivateRoute>	
					
				<PrivateRoute exact path="/docs/specs">
					<Mast />
					<Specs />
				</PrivateRoute>
			</Switch>
		 </div>
	</Router>
	
	</Theme.Provider>
	</UserContext.Provider>
	</authContext.Provider>
	</ProvideAuth>
	)};
export default App;
