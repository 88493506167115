import React, {useState, useEffect} from 'react';

// Firebase
import fire from '../../config/fire-config'
import {getFirestore, collection, getDocs} from 'firebase/firestore';

// Components
import Card from '../../components/cards/Card';
import SocialBar from '../../components/navs/SocialBar';
import Scenes from '../../scenes/components/Scenes';
import Alert from '../../components/cards/CardAlert'

function Events(props) {
	
	const db = getFirestore(fire);
	const [firstHalf, setFirstHalf] = useState([]);
	const [secondHalf, setSecondHalf] = useState([]);
	
	const [alertState, setAlertState] = useState("");
	
	useEffect(() => {
		
		const asyncCall = async () => {
		
			try {
				const snapshot = await getDocs(collection(db, "properties", props.propertyId, "events"));
				
				const eventsAll = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
				const half = Math.ceil(eventsAll.length / 2); 

				setFirstHalf(eventsAll.slice(0, half));
				setSecondHalf(eventsAll.slice(half, eventsAll.length));
			
			} catch {
				//console.log("errored!");
				setAlertState("error");
			}
			
		}
		asyncCall();
		
		return () => {
			//console.log("unmount")
		};
		
	}, [db, props.propertyId])
	
	return (
	<>
	
	{(alertState === "success") &&
		<Alert type="success">
			Success!
		</Alert>
	}
	
	{(alertState === "warning") &&
		<Alert type="warning">
			Warning!
		</Alert>
	}
	
	{(alertState === "danger") &&
		<Alert type="danger">
			Error!
		</Alert>
	}
		
	<div className="row">
		<div className="col-md-6">
		{firstHalf.map((e, i) => (
			<Card
				id={`carEventInterval${i}-left`} key={`left${i}`} time={e.time} heading={e.name} img={e.img}>
				<p>{e.desc}</p>
				<Scenes propertyId={props.propertyId} eventId={e.id} />
				<SocialBar propertyId={props.propertyId} eventId={e.id} edit={e.id} />
			</Card>
		))}
		</div>
		
		<div className="col-md-6">
		{secondHalf.map((e, i) => (
			<Card
				id={`carEventInterval${i}-right`} key={`right${i}`} time={e.time} heading={e.name} img={e.img}>
				<p>{e.desc}</p>
				<Scenes propertyId={props.propertyId} eventId={e.id} />
				<SocialBar propertyId={props.propertyId} eventId={e.id} edit={e.id} />
			</Card>
		))}
		</div>
	</div>
	</>
  );
}

export default Events;
