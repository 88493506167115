import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Firebase
import fire from '../../config/fire-config'
import {getFirestore, collection, getDocs} from 'firebase/firestore';

function Scenes(props) {
	
	const db = getFirestore(fire);
	const [scenes, setScenes] = useState([]);
	
	useEffect(() => {
		
		const col = collection(db, "properties", props.propertyId, 'events', props.eventId, "scenes");
		
		const asyncCall = async () => {
			const snapshot =  await getDocs(col);
			const list = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
			setScenes(list);
		}
		asyncCall();
	
		return () => setScenes()
	
	}, [db, props.propertyId, props.eventId])
	
	return (
	<>
	<h3>Scenes</h3>
	<ul>
		{/* url: /design/room/[Property ID]/[Event ID]/[Scene ID]/[VR (true || false)]/[Orbit (true || false)]  */}
		{scenes && scenes.map((s, i) => (
			<li key={i}><Link to={`/design/room/${props.propertyId}/${props.eventId}/${s.id}/false/true`}>{s.name}</Link> <span className="pe-2">:</span> 
			<Link to={`/design/room/${props.propertyId}/${props.eventId}/${s.id}/true/true`}>VR</Link><span className="px-2">|</span> 
			<Link to={`/design/room/${props.propertyId}/${props.eventId}/${s.id}/true/false`}>Plan</Link>
			</li>
		))}
	</ul>
	</>
  );
}

export default Scenes;
