import styles from './Cards.module.scss';

const Header = (props) => {
	return (
		<h2 className={`card-header ${styles.title}`}>{props.title}</h2>
	);
}

const Card = (props) => {
	var header;
	if (props.title) { header = <Header title={props.title} /> }
	
	return (
	<div id="cardModels" className={`card mb-4 ${styles.panel}`}>
		{header}
		<div className={`card-body ${props.scroll}`}>
		{props.children}
		</div>
	</div>
	)
}
export default Card