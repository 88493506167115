import React, {useEffect, useRef} from 'react';

// Threejs
import * as THREE from 'three';
import {OrbitControls} from '../../node_modules/three/examples/jsm/controls/OrbitControls';

// Components
import NavPlaygrounds from '../components/navs/NavPlaygrounds';

const Page = () => {
	
	const canvasRef = useRef(null);
	
	useEffect(() => {
		
		var scene = new THREE.Scene();
		var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
		camera.position.z = 5;
		var renderer = new THREE.WebGLRenderer({antialias: true});
		//renderer.setClearColor (0xffffff, 1);
		
		renderer.setSize( window.innerWidth, window.innerHeight );
		canvasRef.current.appendChild( renderer.domElement );
		
		// NOTE: Save to variable in case canvasRef changes.
		var canvas = canvasRef.current;
		canvasRef.current.appendChild( renderer.domElement );
		new OrbitControls(camera, canvas);
	
		var geometry = new THREE.BoxGeometry( 1, 1, 1 );
		var material = new THREE.MeshStandardMaterial( { color: 0x00ff00 } );
		var cube = new THREE.Mesh( geometry, material );
		
		// Lights
		const light1 = new THREE.PointLight(0xffffff, 1, 100); //.8
		const light2 = new THREE.PointLight(0Xffffff, 1, 100); //.8
		const light3 = new THREE.PointLight(0Xffffff, 1, 100); //.8
		
		light1.position.set( -1, 1, 2 );
		light2.position.set( 1, 1, 2 );
		light3.position.set( 1, -2, 2 );
	
		scene.add(light1);
		scene.add(light2);
		scene.add(light3);
		scene.add( cube );
		
		var animate = function () {
			requestAnimationFrame( animate );
			//cube.rotation.x += 0.01;
			//cube.rotation.y += 0.01;
			
			//cube.position.x += 0.01;
			
			renderer.render( scene, camera );
		};
		
		let onWindowResize = function () {
			camera.aspect = window.innerWidth / window.innerHeight;
			camera.updateProjectionMatrix();
			renderer.setSize( window.innerWidth, window.innerHeight );
		}

    	window.addEventListener("resize", onWindowResize, false);
    
    	animate();
    	
    	return () => canvas.removeChild( renderer.domElement);
	
  	}, [])


	return (
	<>
	{/* <main className="main-container container mt-3"> */}
	
		<NavPlaygrounds />
		<h1>Cube</h1>
		
		
		
		{/*groups &&
			Object.keys(groups).map(i => (
			<>
				<p>{groups[i].pos.x}</p>
			</>	
			))
		*/}
		
		
		<hr />
		
		<div ref={canvasRef}></div>
	
	{/* </main> */}
	</>	
  );
}

export default Page;
