import React, {useState, useRef, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, deleteDoc} from 'firebase/firestore';

// Threejs
import * as THREE from 'three';
import {OrbitControls} from '../../node_modules/three/examples/jsm/controls/OrbitControls';
import {useLoader} from '../components/three/model-load.js';

// Hooks
import {useModel} from '../components/three/add-model.js';

// Controls
import ObjectSelect from '../components/controls/ObjectSelect.js';
import ObjectAlign from '../components/controls/ObjectAlign/Control.js';
import Material from '../components/controls/Material.js';
import Checkbox from '../components/controls/Checkbox.js';

import Position from '../components/controls/Position.js';
import Rotate from '../components/controls/Rotate.js';
import Scale from '../components/controls/Scale.js';
import Spacing from '../components/controls/Spacing.js';
import RoomMargin from '../components/controls/RoomMargin.js';
import Visibility from '../components/controls/Visibility.js';
//import VisibilityCube from '../components/controls/VisibilityCube.js';

// JSON
import RoomJson from '../components/json/Room.js';

// VR
import {VRButton} from '../../node_modules/three/examples/jsm/webxr/VRButton.js';

// Groups
import Group from '../components/controls/Group.js';

// CSS
import cssCanvas from '../components/css/Canvas.module.scss';
import cssForms from '../components/css/Forms.module.scss';

// Components
import CardModels from '../components/cards/CardModels.js';
import Alert from '../components/cards/CardAlert';

const Page = () => {

	let {propertyId} = useParams();
	let {eventId} = useParams();
	let {sceneId} = useParams();
	const [event, setEvent] = useState(null);
	const [sceneInfo, setSceneInfo] = useState(null);
	const sceneInfoRef = useRef(sceneInfo);
	const [alertState, setAlertState] = useState("");

	// VR
	let {vr} = useParams();
	
	// Orbit
	let {orbit} = useParams();
	
	// Firebase
	const db = getFirestore(fire);
	const [fireData, setFireData] = useState(false);
	
	// Threejs
	const scene = new THREE.Scene();
	const sceneRef = useRef(scene);
	const canvasRef = useRef(null);
	const loader = useRef(useLoader());
	const useModelRef = useRef(useModel());
	
	// Rooms
	const roomRef = useRef(null);
	
	/*** USER INTERFACE CONTROLS ***/
	
	// Select
	const [objSelect, setObjSelect] = useState("cube");
	
	const [nodes, setNodes] = useState([
		{name: "Cube", color: "#f10909", texture: "none", visible: null,
			options: [
				{hex: "#f10909", name: "Red"},
				{hex: "#50D869", name: "Green"},
				{hex: "#1A40FF", name: "Blue"}
			]
		}	
	]);

	// Position, Rotate, Scale
	const [pos, setPos] = useState({x: 0, y: 0, z: 0});
	const [rotate, setRotate] = useState({x: 0, y: 0, z: 0});
	const [scale, setScale] = useState({x: 1, y: 1, z: 1});
	const [size, setSize] = useState({x: 1, y: 1, z: 1});
	
	// Spacing & Align
	const [spacing, setSpacing] = useState({x: 0, y: 0, z: 0});
	const [objAlign, setObjAlign] = useState("center");
	
	// Rows & Columns
	const [rows, setRows] = useState(1);
	const [cols, setCols] = useState(1);

	/*** SETTINGS ***/
	
	// Groups
	const [groupSelect, setGroupSelect] = useState("center");
	const [groupName, setGroupName] = useState("group0");
	const [forceUpdate, setForceUpdate] = useState(0);
	const groupsRef = useRef([{name: "center", group: null, pos: {x: 0, y: 0, z: 0}}]);
	const [groupPos, setGroupPos] = useState({x: 0, y: 0, z: 0});
	
	const handleAddGroup = () => {
		
		// 1) Add group to local array
		groupsRef.current.push( {name: groupName, group: new THREE.Group(), pos: {x: 0, y: 0, z: 0}} );
		
		// 2)  Add newly create group to scene
		const foundGroup = groupsRef.current.find(obj => obj.name === groupName);
							
		if (foundGroup) {
		
			const group = foundGroup.group;
			sceneRef.current.add(group);
			
			if (fireData) {
				async function addGroupAsync() {
					try {
						await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', sceneId, "groups", groupName), {
							name: groupName,
							pos: {x: 0, y: 0, z: 0}
						});
						await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', sceneId, "groups", groupName, "models"), {});
					} catch (e) {
						console.error("Error adding document: ", e);
						setAlertState("Error adding document: ", e)
					}
				}	
				addGroupAsync();
			}
		}
		
		setForceUpdate(2); // Forces re-render
	}
	
	const handleDeleteGroup = () => {
	
		// 1) Remove group from scene
		const foundGroup = groupsRef.current.find(obj => obj.name === groupSelect);
		
		if (foundGroup) {
		
			const group = foundGroup.group;
			
			// Remove mesh from group
			for (var i = group.children.length - 1; i >= 0; i--) {
				group.remove(group.children[i]);
			}
			
			sceneRef.current.remove(group);
		}
		
		// 2) Remove group from groups array
		var filtered  = groupsRef.current.filter( 
			(obj) => {
				return obj.name !== groupName;
				
			} 
		); 
		groupsRef.current = filtered;
		// Forces rerender
		setForceUpdate(6);
	}
	
	var handleMoveGroup = () => {
		
		const foundGroup = groupsRef.current.find(obj => obj.name === groupSelect);
		const posX = groupPos.x;
		const posZ = groupPos.z;
		
		// Update React group ?
		foundGroup.pos.x = posX;
		foundGroup.pos.z = posZ;
		
		// Update Threejs group		
		foundGroup.group.position.set(posX, 0, posZ);
	}
	
	useEffect(() => {
		
		// Threejs
		const renderer = new THREE.WebGLRenderer({antialias: true});
		const camera = new THREE.PerspectiveCamera(75, window.innerWidth/window.innerHeight, 0.1, 1000);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setClearColor (0xffffff, 1);
		
		// NOTE: Save to variable in case mountRef changes.
		var canvas = canvasRef.current;
		canvasRef.current.appendChild( renderer.domElement );
		
		// Cameras
		if (orbit === "false") {
			var degrees = -90 * (Math.PI / 180)
			camera.position.set( 0, 30, 0);
			camera.rotation.set(degrees, 0, 0);
		} else {
			camera.position.z = 10;
			new OrbitControls(camera, canvas);
		}
		
		// VR & animation
		document.getElementById("vr-controls").appendChild( VRButton.createButton( renderer ) )
		renderer.xr.enabled = true
		
		if (vr === "true") {
		
			renderer.setAnimationLoop( function () {
				renderer.render( scene, camera );
			});
		} else { 
		
			// No VR
			var animate = function () {
				requestAnimationFrame( animate );
				renderer.render( sceneRef.current, camera );
			};
			animate();
		}
		
		// Window
		let onWindowResize = function () {
			camera.aspect = window.innerWidth / window.innerHeight;
			camera.updateProjectionMatrix();
			renderer.setSize( window.innerWidth, window.innerHeight );
		}
    	window.addEventListener("resize", onWindowResize, false);
    	
    	// Event & scene
    	async function getEvent(db) {
	
			const col = doc(db, "properties", propertyId,  'events', eventId);
			const snapshot = await getDoc(col);
			
			if (snapshot.exists()) {
				setEvent(snapshot.data());
			}
		}	
		getEvent(db);
		
		async function getScene(db) {
	
			const col = doc(db, "properties", propertyId, 'events', eventId, 'scenes', sceneId);
			const snapshot = await getDoc(col);
			
			if (snapshot.exists()) {
				setSceneInfo(snapshot.data());
				sceneInfoRef.current = snapshot.data();
				roomRef.current = RoomJson.[propertyId].find(obj => obj.name === sceneInfoRef.current.room);
				initRoom();
			}
		}	
		getScene(db);
		
    	// Room
    	var initRoom = () => {
    		
			const obj = {
				"name": sceneInfoRef.current.room,
				"modelUrl": "/models/rooms/ballroom/room.gltf",
				"floorTexture": "/img/textures/floors/oak.jpg"
			};
			
			obj.url = roomRef.current.modelUrl;
			obj.material = {color: "#ffffff", texture: roomRef.current.floorTexture, UVMap: {u: 20, v: 20}};
			loader.current.addRoom(obj, sceneRef.current);
    	}
    	
    	// Firestore
		async function getFireModels(db) {
		
			// Groups
			const colGroups = collection(db, "properties", propertyId, 'events', eventId, 'scenes', sceneId, "groups");
			const snapshotGroups = await getDocs(colGroups);
			
			groupsRef.current = snapshotGroups.docs.map(doc => doc.data());
			groupsRef.current.map(async function(item) {
			
				item.group = new THREE.Group();		
				
				// Models
				const colModels = collection(db, "properties", propertyId, 'events', eventId, 'scenes', sceneId, 'groups', item.name, 'models');
				const snapshotModels = await getDocs(colModels);
				const models = snapshotModels.docs.map(doc => doc.data());
				
				// Add models to group and scene
				models.map((obj) => {
					
					const model = obj.type;
					useModelRef.current.addModel(model, obj, item.group, sceneRef.current);
					return null;
				});
					
				return null;
			});
			
			setForceUpdate(1)
		}	
		getFireModels(db);
				
    	return () => canvas.removeChild( renderer.domElement);
	
  	}, [db, propertyId, eventId, sceneId])

  	var handleEditModels = (e, editState) => {
  		
  		const foundGroup = groupsRef.current.find(obj => obj.name === groupSelect);
  		const group = foundGroup.group;
  		
  		for (var i = group.children.length - 1; i >= 0; i--) {
			if (group.children[i].name === objSelect) {group.remove(group.children[i]);}
		}
		
		
		async function deleteFireModels() {
			
			if (fireData) {
			
				const q = query(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', sceneId, 'groups', groupSelect, 'models'), where("name", "==", objSelect));

				const querySnapshot = await getDocs(q);
				querySnapshot.forEach((file) => {
				
					const myFileRef = doc(db, 'properties', propertyId, 'events', eventId, 'scenes', sceneId, 'groups', groupSelect, 'models', file.id);
					deleteDoc(myFileRef);
				});
			}
			
			if (editState !== "delete") {handleAddModels(e)}
		}
		deleteFireModels();
		
  	}
  	
  	// Add multiple model
	var handleAddModels = (e) => {
	
		const model = e.target.value;
		
		const foundGroup = groupsRef.current.find(obj => obj.name === groupSelect);
		const group = foundGroup.group;
		
		let spacingX = parseFloat(spacing.x);
		let spacingZ = parseFloat(spacing.z);
		
		const posX = parseFloat(pos.x);
		const posY = parseFloat(pos.y);
		const posZ = parseFloat(pos.z);
		
		const rotateX = rotate.x;
		const rotateY = rotate.y;
		const rotateZ = rotate.z;
		
		let scaleX = parseFloat(scale.x);
		let scaleY = parseFloat(scale.y);
		let scaleZ = parseFloat(scale.z);
		
		let sizeX = size.x;
		let sizeY = size.y;
		let sizeZ = size.z;
		
		let widthX = 0;
		//let widthY = 0;
		let widthZ = 0;
		
		widthX = sizeX + spacingX;
		
		// Offsets
		var xOffsetHalf = ((sizeX + spacingX)  * cols) / 2;
		var zOffsetHalf = ((sizeZ + spacingZ) * rows) / 2;
		
		for (let r = 0; r < rows; r++) { 
			
			if ((objAlign === "back left") || (objAlign === "back right") || (objAlign === "back")) {
				widthZ = [r] * -(sizeZ + spacingZ);
			} else {
				widthZ = [r] * (sizeZ + spacingZ);
			}
			
			for (let i = 0; i < cols; i++) { 
				
				let placeX = 0; 
				let placeY = posY;
				let placeZ = 0;
				
				const sizeHalfX = sizeX/2;
				const sizeHalfY = sizeY/2;
				const sizeHalfZ = sizeX/2;
				
				switch(objAlign) {
					case "front left":

						placeX = ([i] * widthX) + posX;
						//placeY = ([i] * widthY) + posY;
						placeZ = widthZ + posZ;
						
						// Offset pos					
						placeX = placeX + sizeHalfX;
						placeZ = placeZ + sizeHalfZ;
						break;
						
					case "front":
						placeX = (([i] * widthX) + posX) - xOffsetHalf;
						placeZ = widthZ + posZ;
						
						// Offset pos					
						placeX = (placeX + sizeHalfX) + (spacingX/2);
						placeZ = placeZ + sizeHalfZ;
					break;	

					case "front right":

						placeX = -([i] * widthX) + posX;
						//placeY = ([i] * widthY) + posY;
						placeZ = widthZ + posZ;
						
						// Offset pos					
						placeX = placeX - sizeHalfX;
						placeZ = placeZ + sizeHalfZ;
						break;
						
					case "left":
					
						placeX = ([i] * widthX) + posX;
						placeZ = (widthZ + posZ) - zOffsetHalf;
						
						// Offset pos
						placeX = placeX + sizeHalfX;
						placeZ = (placeZ + sizeHalfZ) + (spacingZ/2);
					break;	

					case "center":

						placeX = (([i] * widthX) + posX) - xOffsetHalf;
						//placeY = (([i] * widthY) + posY);
						placeZ = (widthZ + posZ) - zOffsetHalf;
						
						// Offset pos
						placeX = (placeX + sizeHalfX) + (spacingX/2);
						placeZ = (placeZ + sizeHalfZ) + (spacingZ/2);
						break;
						
					case "right":
					
						placeX = -([i] * widthX) + posX;
						placeZ = (widthZ + posZ) - zOffsetHalf;
						
						// Offset pos					
						placeX = placeX - sizeHalfX;
						placeZ = (placeZ + sizeHalfZ) + (spacingZ/2);
					break;
	

					case "back left":
					
						placeX = ([i] * widthX) + posX;
						//placeY = ([i] * widthY) + posY;
						placeZ = widthZ + posZ;
						
						// Offset pos
						placeX = placeX + sizeHalfX;
						placeZ = placeZ - sizeHalfZ;
					break;
					
					case "back":
						placeX = (([i] * widthX) + posX) - xOffsetHalf;
						placeZ = widthZ + posZ;
						
						// Offset pos
						placeX = (placeX + sizeHalfX) + (spacingX/2);
						placeZ = placeZ - sizeHalfZ;  
					break;

					case "back right":
						
						placeX = -([i] * widthX) + posX;
						//placeY = ([i] * widthY) + posY;
						placeZ = widthZ + posZ;
						
						// Offset pos
						placeX = placeX - sizeHalfX;
						placeZ = placeZ - sizeHalfZ;  
					break;
					
					default:
						
						placeX = (([i] * widthX) + posX) - xOffsetHalf;
						//placeY = (([i] * widthY) + posY);
						placeZ = (widthZ + posZ) - zOffsetHalf;
						
						// Offset pos
						placeX = (placeX + sizeHalfX) + (spacingX/2);
						placeZ = (placeZ + sizeHalfZ) + (spacingZ/2);
				}
				
				// Offset Primitives
				if (model === "cube" || model === "sphere" || model === "danceFloorUnit") {
					placeY = posY + sizeHalfY;
				}
				
				// Deep clone and remove options
				const cloneNodes = JSON.parse(JSON.stringify(nodes));
				cloneNodes.map((obj) => {
					delete obj.options; 
					return obj; 
				})
				
				let obj = {
					name: model, 
					type: model, 
					nodes: cloneNodes,
					pos: {x: placeX, y: placeY, z: placeZ},
					rotate: {x: rotateX, y: rotateY, z: rotateZ}, 
					scale: {x: scaleX, y: scaleY, z: scaleZ}
				};
				
				useModelRef.current.addModel(model, obj, group, sceneRef.current);
				
				if (fireData) {
					addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', sceneId, 'groups', groupSelect, 'models'), obj);
				}
			}
		}
	}
	
	return (
	<>
	<div className={`${cssCanvas.designer}`}>
		
		<div className={cssForms.alertPanel}>
			{(alertState === "success") &&
				<Alert type="success">
					Success! View new scene from <Link exact to="/dashboard">Dashboard</Link>.
				</Alert>
			}
		
			{(alertState === "warning") &&
				<Alert type="warning">
					Warning!
				</Alert>
			}
		
			{(alertState === "danger") &&
				<Alert type="danger">
					Error!
				</Alert>
			}
		</div>
	
		{(vr === "false") && 
		<>
		<ul className={`nav btn-group ${cssCanvas.canvasNav} mt-2`}>
			<li className="nav-item dropdown me-3">
				<button title="Group" type="button" className={`btn ${cssCanvas.btn}  ${cssCanvas.btnGroup}`} data-bs-toggle="dropdown" aria-expanded="false"></button>
				<div className={`dropdown-menu dropdown-menu-end ${cssCanvas.canvasDropdownMenu }`}>
				<CardModels>
					<Group group={groupSelect} setGroupSelect={setGroupSelect} options={groupsRef.current} />
					<div className="mb-3 row">
						<label htmlFor="rowsRepeat" className="form-label col-6">Group name:</label>
						<div className="col-6">
							<input id="addGroup" className="form-control form-control-sm" type="text" value={groupName} size="4"
								onChange={e => {
									const val = e.target.value;
									setGroupName(val)
								}
							} />
						</div>	
					</div>
					<Position type="group" pos={groupPos} setPos={setGroupPos} />
					<hr />
					<button type="button" value={objSelect} className={`btn btn-primary btn-panel mt-3`} onClick={(e) => {handleAddGroup(groupSelect)}}>Add</button>
					<button type="button" className={`btn btn-primary btn-panel mt-3 ms-3`} onClick={() => {handleDeleteGroup()}}>Delete</button>
					<br />
					<button type="button" className={`btn btn-primary btn-panel mt-3`} onClick={() => {handleMoveGroup()}}>Move</button>
				</CardModels>
				</div>
			</li>
			<li className="nav-item dropdown me-3">
				<button title="Info" type="button" className={`btn ${cssCanvas.btn} ${cssCanvas.btnGear}`} data-bs-toggle="dropdown" aria-expanded="false"></button>
				<div className={`dropdown-menu dropdown-menu-end ${cssCanvas.canvasDropdownMenu}`}>
				<CardModels>
					{sceneInfo &&
						<>
						<RoomMargin propertyId={propertyId} sceneInfo={sceneInfo} sceneRef={sceneRef.current}  />
						{/* 
						<hr />
						<VisibilityCube sceneRef={sceneRef.current} />
						*/}
						</>
					}
					<Checkbox id="fireData" label="Firestore Save" checked={fireData} setChecked={setFireData}  />
				</CardModels>	
				</div>
			</li>
			<li className="nav-item dropdown">
				<button title="Info" type="button" className={`btn ${cssCanvas.btn}  ${cssCanvas.btnInfo}`} data-bs-toggle="dropdown" aria-expanded="false"></button>
				<div className={`dropdown-menu dropdown-menu-end ${cssCanvas.canvasDropdownMenu}`}>
				<CardModels>
					<dl className="row">
						<dt className="col-4">Event: </dt>
						<dd className="col-8">{event && event.name}</dd>
						</dl>
					<dl className="row">
						<dt className="col-4">Scene: </dt>
						<dd className="col-8">{sceneInfo && sceneInfo.name}</dd>
					</dl>
					<dl className="row">
						<dt className="col-4">Hotel: </dt>
						<dd className="col-8">Hilton Downtown</dd>
					</dl>
					<dl className="row">
						<dt className="col-4">Vendors: </dt>
						<dd className="col-8"><Link to="/vendors">View</Link></dd>
					</dl>
				</CardModels>	
				</div>
			</li>
		</ul>
		
		 <div className={`${cssCanvas.panels} mt-3`}>
			<CardModels>
				<div className="row navPanel justify-content-center mb-4">
					<ul className="nav nav-pills justify-content-center" role="tablist">
						<li className="nav-item" role="presentation">
							<button id="objects-tab" className="nav-link btn-sm active" data-bs-toggle="tab" data-bs-target="#objects" aria-current="page" href="#">Objects</button>
						</li>
						<li className="nav-item" role="presentation">
							<button id="rooms-tab" className="nav-link btn-sm" data-bs-toggle="tab" data-bs-target="#rooms" aria-current="page" href="#">Layout</button>
						</li>
					</ul>
				</div>
		
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="objects" role="tabpanel" aria-labelledby="objects-tab">
				
						<ObjectSelect  
							objSelect={objSelect} setObjSelect={setObjSelect} 
							setSpacing={setSpacing} spacing={spacing} 
							setScale={setScale} scale={scale}
							setSize={setSize} size={size}  
							nodes={nodes} setNodes={setNodes} 
							rows={rows} setRows={setRows}
							cols={cols} setCols={setCols} 
						/>
				
						<hr />
						
						{/* 
						<div className={cssForms.heading} aria-level="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGroup" aria-expanded="true" aria-controls="collapseGroup">Group</div>
						<div className="collapse mt-2 show" id="collapseGroup">
							<Group group={groupSelect} setGroupSelect={setGroupSelect} options={groupsRef.current} />
						</div>
						<hr />
						*/}
				
						<div className={cssForms.heading} aria-level="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMaterial" aria-expanded="false" aria-controls="collapseMaterial">Materials</div>
						<div className={`collapse ${cssForms.collapseMaterial} pt-2`} id="collapseMaterial">
							{(nodes) &&
								nodes.map((value, i) => (
								<div key={i}>
									<h6>{value.name}</h6>
									{value.color != null  &&
										<Material id={`${i} material`} node={value} setNodes={setNodes}/>
									}
									{value.visible != null  &&
										<Visibility id={`${i} visible`} node={value} setNodes={setNodes} />
									}
									<hr />
								</div>
								))
							}
						</div>	
					</div>
			
					<div className="tab-pane fade" id="rooms" role="tabpanel" aria-labelledby="rooms-tab">
				
						<hr />
				
						<div className={cssForms.heading} aria-level="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePos" aria-expanded="false" aria-controls="collapsePos">Position</div>
						<div className="collapse" id="collapsePos">
							<ObjectAlign 
								pos={pos} 
								setPos={setPos}
								objAlign={objAlign}  
								setObjAlign={setObjAlign} 
								sceneInfo={sceneInfo}
								propertyId={propertyId} 
								group={groupSelect} setGroupSelect={setGroupSelect}
							/>
							<Position pos={pos} setPos={setPos} />
						</div>
						<hr />
				
						<div className={cssForms.heading} aria-level="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCols" aria-expanded="false" aria-controls="collapseCols">Columns & Rows</div>
						<div className="collapse" id="collapseCols">
							<div className="mb-3 mt-3 row">
								<label htmlFor="rowsRepeat" className="col-6 form-label">Rows:</label>
								<div className="col-6">
									<input id="rowsRepeat" className="form-control form-control-sm" type="number" value={rows} size="4"
										onChange={e => {
											const val = e.target.value;
											setRows(val)
										}
									} />
								</div>
							</div>
				
							<div className="mb-3 mt-3 row">
								<label htmlFor="colsRepeat" className="col-6 form-label">Columns:</label>
								<div className="col-6">
									<input id="colsRepeat" className="form-control form-control-sm" type="number" value={cols} size="4"
										onChange={e => {
											const val = e.target.value;
											setCols(val)
										}
									} />
								</div>
							</div>
						</div>
						<hr />
				
						<div className={cssForms.heading} aria-level="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseScale" aria-expanded="false" aria-controls="collapseScale">Scale & Rotate</div>
						<div className="collapse" id="collapseScale">						
							<Scale scale={scale} handleScaleChange={setScale} />
							<Rotate rotate={rotate} setRotate={setRotate} />
						</div>
						<hr />
				
						<div className={cssForms.heading} aria-level="3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSpacing" aria-expanded="false" aria-controls="collapseSpacing">Spacing</div>
						<div className="collapse" id="collapseSpacing">
							<Spacing spacing={spacing} setSpacing={setSpacing} />
						</div>		
					</div>
					<hr />
			
					<button type="button" value={objSelect} className={`btn btn-primary btn-panel mt-3`} onClick={(e) => {handleAddModels(e)}}>Add</button>
					<button type="button" value={objSelect} className={`btn btn-primary btn-panel mt-3 ms-3`} onClick={(e) => {handleEditModels(e)}}>Replace</button>
					<br />
					<button type="button" value={objSelect} className={`btn btn-primary btn-panel mt-3`} onClick={(e) => {handleEditModels(e, "delete")}}>Delete</button>
				</div>
			</CardModels>
		</div>
		</>
		}
		
		<div id="vr-controls"></div>
		<div ref={canvasRef}></div>
	</div>	 
	</>
  );
}
export default Page;
