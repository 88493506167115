import React from 'react';

const Rotate = (props) => {
	return (
	<div className="mb-3 row">
		<label htmlFor="rotateY" className="col-6 form-label">Rotate:</label>
		<div className="col-6">
			<input id="rotateY" className="form-control form-control-sm" type="number" value={props.rotate.y} size="4"
				onChange={e => {
					props.setRotate(
						prevState => {
							return {...prevState, y: e.target.value };
						}
					)
				}
			} />
		</div>
	</div>
	)
}
export default Rotate;
