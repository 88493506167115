import React from 'react';

const Spacing = (props) => {
	return (
	<>
	<div className="mb-3 row">
		<label htmlFor="spacingX" className="col-6 form-label">x:</label>
		<div className="col-6">
			<input id="spacingX" className="form-control form-control-sm" type="number" value={props.spacing.x} size="4"
				onChange={e => {
					props.setSpacing(
							prevState => {
							return {...prevState, x: e.target.value };
						}
					)
				}
			} />
		</div>
	</div>
	<div className="mb-3 row">
		<label htmlFor="spacingZ" className="col-6 form-label">z:</label>
		<div className="col-6">
			<input id="spacingZ" className="form-control form-control-sm" type="number" value={props.spacing.z} size="4" 
				onChange={e => {
					props.setSpacing(
							prevState => {
							return {...prevState, z: e.target.value };
						}
					)
				}
			} />
		</div>
	</div>		
	</>
	)
}
export default Spacing;
