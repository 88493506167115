const RoomJson = {
	"aLnoVH4XjnQxxeenyQDz" : [
		{
			"name": "Ballroom",
			"width": {"x": 25.6012, "z": 23.7712},
			"roomMargin": 0.9144,
			"modelUrl": "/models/rooms/ballroom/room.gltf",
			"floorTexture": "/img/textures/floors/oak.jpg",  
		},
		{
			"name": "Jr Ballroom",
			"width": {"x": 20.1168, "z": 10.3568},
			"roomMargin": 0.9144,
			"modelUrl": "/models/rooms/ballroom-jr/room.gltf",
			"floorTexture": "/img/textures/floors/carpet-pattern.jpg",   
		} ,
		{
			"name": "Large Meeting Room",
			"width": {"x": 13.4112, "z": 5.7912},
			"roomMargin": 0.9144,
			"modelUrl": "/models/rooms/meeting-room-a/room.gltf",
			"floorTexture": "/img/textures/floors/carpet-pattern.jpg",   
		},
		{
			"name": "Small Meeting Room",
			"width": {"x": 6.0962, "z": 4.8772},
			"roomMargin": 0.9144,
			"modelUrl": "/models/rooms/meeting-room-b/room.gltf",
			"floorTexture": "/img/textures/floors/carpet.png",   
		}
	]
}
export default RoomJson;