import React from 'react';

const Scale = (props) => {
	return (
	<>
	<div className="mb-3 row">
		<label htmlFor="posX" className="col-6 form-label">x:</label>
		<div className="col-6">
			<input id="posX" className="form-control form-control-sm" type="number" value={props.scale.x} size="6"
				onChange={e => {
					props.handleScaleChange(
						prevState => {
							return {...prevState, x: e.target.value };
						}
					)
				}
			} />
		</div>
	</div>
	<div className="mb-3 row">
		<label htmlFor="posY" className="col-6 form-label">y:</label>
		<div className="col-6">
			<input id="posY" className="form-control form-control-sm" type="number" value={props.scale.y} size="6"
				onChange={e => {
					props.handleScaleChange(prevState => {
						return {...prevState, y: e.target.value };
					})
				}
			} />
		</div>
	</div>
	<div className="mb-3 row">
		<label htmlFor="posZ" className="col-6 form-label">z:</label>
		<div className="col-6">
			<input id="posZ" className="form-control form-control-sm" type="number" value={props.scale.z} size="6"
				onChange={e => {
					props.handleScaleChange(prevState => {
						return {...prevState, z: e.target.value };
					})
				}
			} />
		</div>
	</div>
	</>
	)
}
export default Scale;
