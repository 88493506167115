import {Link, useHistory} from 'react-router-dom';

function Mast() {

	let history = useHistory();
	
	return (
	<header className="navbar navbar-scene navbar-dark container-fluid">
		<ul className="navbar-nav nav-pills mb-md-0">
			<li><button className="nav-link" onClick={() => history.goBack()}>&#5176;</button></li>
		</ul>
		<Link to="/" className="navbar-brand navbar-brand-scene"></Link>
	</header>
	);
}
export default Mast;
