import React from 'react';
import {Link} from 'react-router-dom';
import styles from '../css/SocialBar.module.css'

function SocialBar(props) {
	
	//const [topic, setTopic] = useContext(Topic)
	//const collapseTarget = `comment-${props.topicId}`
		
	return (
		<>
		<div className={styles.socialBar}>
			<div className="row">
				<div className="col-12">
					
				</div>
			</div>
			<hr className={styles.hrSocialBar} /> 
			<div className="row d-flex">
				<div className="col-12">
					{/* 
					<button type="button" 
						className={`iconButton ${styles.iconComment}`} title="Comments">
						Comment:
					</button>
					*/}
					{/* 
					<Link className={`iconButton ${styles.iconEdit}`} to={`/event/${props.eventId}`}>Edit</Link>
					*/}
					
					<Link className={`iconButton ${styles.iconEdit}`} to={`/event/${props.propertyId}/${props.eventId}`}>Edit</Link>
					<Link className={`iconButton ${styles.iconAddScene}`} to={`/scene-add/${props.propertyId}/${props.eventId}`}>Scene</Link>
				</div>
				<div className="col-6 d-flex justify-content-end">
					
				</div>
			</div>
		</div>
		 </>
	);
}
export default SocialBar