import {useHistory} from 'react-router-dom';

function Page() {
	
	let history = useHistory();
	
	return (
	<main className="main-container container mt-3">
	<div className="row mt-4 justify-content-center">
		<div className="col-8">
			<h1>Success!</h1>
			<p>You've successfully created a new scene!</p>
			<hr />
			<button type="button" onClick={() => history.goBack()}  className="btn btn-primary mt-3">Create new scene</button>
			<button type="button" onClick={() => history.push("/dashboard")}  className="btn btn-primary mt-3 ms-3">View new scene on Dashboard</button>
		</div>
	</div>
	</main>
	);
}
export default Page;
