import {useRef} from 'react';
import * as THREE from 'three';
import { GLTFLoader } from '../../../node_modules/three/examples/jsm/loaders/GLTFLoader.js';
import {useLights} from "./use-lights.js";

export function useLoader(model) {

	const lights = useRef(useLights());
	
	const metal = new THREE.MeshStandardMaterial({color:'#CACACA', roughness:0.2, metalness: .5});
	const blackPlatic = new THREE.MeshLambertMaterial({color: "#999999"});
	const blackMetal = new THREE.MeshStandardMaterial({color:'#999999', roughness:0.2, metalness: 1});
	const screenGlass = new THREE.MeshLambertMaterial({color: "#cccccc"});
	// const mahogany = new THREE.MeshStandardMaterial({color:'#c04000', roughness:0.2, metalness: .5});
	const clothMaterial = new THREE.MeshLambertMaterial({color:'#f3edd5'});
	
	const wood = new THREE.MeshStandardMaterial({color: "#895F3E"});
	const textureWood = new THREE.TextureLoader().load("/img/textures/wood/oak.jpg");
	textureWood.wrapS = THREE.RepeatWrapping;
	textureWood.wrapT = THREE.RepeatWrapping;
	textureWood.repeat.set( 4, 4 );
	
    const glass = new THREE.MeshStandardMaterial({
    	color:"#D0FFF7",
    	roughness: 0,
    	metalness: .3,
    	transparent: true,
        opacity: .6,
    });

	const add = (model) => {
		return new Promise (resolve => {
			new GLTFLoader().load(model.url, resolve);
		});
	}
	
	var addRoom = (obj, scene) => {
	
		// Lights
		switch(obj.name) {
			case "Large Meeting Room":
				lights.current.getLargeMeetingroomLights(scene);
			break;

			case "Small Meeting Room":
				lights.current.getSmallMeetingroomLights(scene);
			break;
			
			case "Jr Ballroom":
				lights.current.getJrBallroomLights(scene);
			break;
			
			case "Ballroom":
				lights.current.getBallroomLights(scene);
			break;		
				
			default: 
				lights.current.getBallroomLights(scene);
		}
		
		add(obj).then(result => {
				
			const model = result.scene;
			
			if (obj.material.UVMap) {
				
				var u = obj.material.UVMap.u || 10;
				var v = obj.material.UVMap.v || 10;
			}
		
			if (obj.material.texture && obj.material.texture !== "none") {
		
				var loader = new THREE.TextureLoader();
				var texture = loader.load(obj.material.texture, function ( texture ) {
					
					if (obj.material.UVMap) {
						texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
						texture.offset.set(u, v);
						texture.repeat.set(u, v);
					}
				} );
				
				model.children[4].material.map = texture;	
			}
			
			//model.visible = false;
			model.name = obj.name;
			scene.add(model);
			model.position.set(-.15, -.1, -.15); // -.3 offset accounts for wall width
		});
	}
	
	var addTable = (obj, group, scene) => {
		
		add(obj).then(result => {
				
			const model = result.scene;
			
			/** NODES **/
			
			// Table and chairs
			const table = model.getObjectByName("Table");
			const legs = model.getObjectByName("Legs");
			const cloth = model.getObjectByName("Cloth");
			const chairs = model.getObjectByName("Chairs");
			const cushions = model.getObjectByName("Cushions");
			
			// Table settings
			const glasses = model.getObjectByName("Glasses");
			const utensils = model.getObjectByName("Utensils");
			const plates = model.getObjectByName("Plates");
			const napkins = model.getObjectByName("Napkins");
			
			const vase = model.getObjectByName("Vase");
			const pedals = model.getObjectByName("Pedals");
			const stems = model.getObjectByName("Stems");
			

			/** COLORS **/
			
			// Table and chairs
			const tableColor = new THREE.MeshLambertMaterial({color: "#6B5B50"});
			table.material = tableColor;
			
			if (legs) {
				legs.material = metal;
			}
			
			if (cloth) {
				const clothNode = obj.nodes.find(obj => obj.name === "Cloth");
				
				if (clothNode) {
					const clothColor = new THREE.MeshLambertMaterial({color: clothNode.color});
					cloth.material = clothColor;
					cloth.visible = clothNode.visible;
				} else {
					cloth.material = clothMaterial;
				}
			}
			
			if (obj.name === "table60inChiavari" || obj.name === "table66inChiavari" || obj.name === "table72inChiavari"
			|| obj.name === "table18inBanquet" || obj.name === "table30inBanquet") {
				
				if (chairs) {
					const frameNode = obj.nodes.find(obj => obj.name === "Frame");
					const frameColor = new THREE.MeshStandardMaterial({color: frameNode.color, roughness:0.2, metalness: .1,});
					chairs.material = frameColor;
				}
			}
			
			if (obj.name === "table60in" || obj.name === "table66in" || obj.name === "table72in") {
				
				if (chairs) {
					chairs.material = metal;
				}
			}
			
			if (cushions) {
			
				let cushionColor = new THREE.MeshLambertMaterial({color: clothMaterial});
				cushions.material = cushionColor;
				
				const cushionNode = obj.nodes.find(obj => obj.name === "Cushion");
				if (cushionNode) {
					cushionColor = new THREE.MeshLambertMaterial({color: cushionNode.color});
					cushions.material = cushionColor;
					cushions.visible = cushionNode.visible;
				}
			}
			
			if (obj.name === "table18inMeeting" ||  obj.name === "table30inMeeting") {
				
				if (chairs) {
					const frameNode = obj.nodes.find(obj => obj.name === "Frame");
					chairs.material = metal;
					chairs.visible = frameNode.visible;
				}
			}
			
			// Table settings
			
			if (plates) {
				const plateColor = new THREE.MeshLambertMaterial({color: "#ffffff"});
				plates.material = plateColor;
				
				const platesNode = obj.nodes.find(obj => obj.name === "Plates");
				if (
					platesNode) {
						plates.visible = platesNode.visible;
						glasses.visible = platesNode.visible;
						utensils.visible = platesNode.visible;
						napkins.visible = platesNode.visible;
					}
			}
		
			if (glasses) {
				glasses.material = glass;
				
				//const glassesNode = obj.nodes.find(obj => obj.name === "Glasses");
				//if (glassesNode) {glasses.visible = glassesNode.visible;}
			}
			
			if (utensils) {
				utensils.material = metal;
				
				//const utensilsNode = obj.nodes.find(obj => obj.name === "Glasses");
				//if (utensilsNode) {utensils.visible = utensilsNode.visible;}
			}
			
			if (napkins) {
			
				let napkinsColor = new THREE.MeshLambertMaterial({color: "#E8CD78"});
				napkins.material = napkinsColor;
				
				const napkinsNode = obj.nodes.find(obj => obj.name === "Napkins");
				if (napkinsNode) {
					napkinsColor = new THREE.MeshLambertMaterial({color: napkinsNode.color});
					napkins.material = napkinsColor;
					//napkins.visible = napkins.visible;
				}
			}
			
			if (vase) {
				const pedalColor = new THREE.MeshLambertMaterial({color: "#EC94E9"});
				const stemColor = new THREE.MeshLambertMaterial({color: "#346345"});
				
				vase.material = glass;
				pedals.material = pedalColor;
				stems.material = stemColor;
				
				const vaseNode = obj.nodes.find(obj => obj.name === "Vase");
				if (vaseNode) {
					vase.visible = vaseNode.visible;
					pedals.visible = vaseNode.visible;
					stems.visible = vaseNode.visible;
				}
			}
			
			model.name = obj.name;
			group.add(model)
			scene.add(group);
	
			model.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
			model.rotation.set(obj.rotate.x, obj.rotate.y, obj.rotate.z);
		});
	}
	
	var addChair = (obj, group, scene) => {
		
		add(obj).then(result => {

			const model = result.scene;
			const frame = model.getObjectByName("Frame");
			const cushion = model.getObjectByName("Cushion");
			
			if (cushion) {
				const cushionNode = obj.nodes.find(obj => obj.name === "Cushion");
				const cushionColor = new THREE.MeshLambertMaterial({color: cushionNode.color});
				cushion.material = cushionColor;
				
				cushion.visible = cushionNode.visible;
			}
			
			if (obj.name === "chairChiavari") {
				
				const frameNode = obj.nodes.find(obj => obj.name === "Frame");
				const frameColor = new THREE.MeshStandardMaterial({color: frameNode.color, roughness:0.2, metalness: .1,});
				frame.material = frameColor;
			}
			
			if (obj.name === "chairLancaster") {
				frame.material = metal;
			}
			
			/*
			if (obj.texture && obj.texture !== "none") {
				model.children[1].material.map = texture;
				//model.children[2].material.map = texture;
			}
			*/
			
			model.name = obj.name;
			group.add(model)
			scene.add(group);
	
			model.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
			model.rotation.set(obj.rotate.x, obj.rotate.y, obj.rotate.z);
		});
	}
	
	var addPortableBar = (obj, group, scene) => {
	
		//const wood = new THREE.MeshStandardMaterial({color: "#895F3E"});
	
		add(obj).then(result => {
		
			const model = result.scene;
			
			const particleBoard = new THREE.MeshLambertMaterial({color: "#404144"});
			const granite = new THREE.MeshLambertMaterial({color: "#A2A5A0"});
			
			const bar = model.getObjectByName("Bar");
			const barTop = model.getObjectByName("BarTop");
			const sink = model.getObjectByName("Sink");
			const floor = model.getObjectByName("Floor");
			const cabinet = model.getObjectByName("Cabinet");
			
			bar.material = wood;
			bar.material.map = textureWood;
			barTop.material = granite;
			sink.material = metal;
			floor.material = particleBoard;
			cabinet.material = particleBoard;
			
			// Textures maps
			/*
			const textureWood = new THREE.TextureLoader().load("/img/textures/wood/oak.jpg");
			textureWood.wrapS = THREE.RepeatWrapping;
			textureWood.wrapT = THREE.RepeatWrapping;
			textureWood.repeat.set( 4, 4 );
			*/
			
			
			
		
			
			model.name = obj.name;
			group.add(model)
			scene.add(group);
	
			model.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
			model.rotation.set(obj.rotate.x, obj.rotate.y, obj.rotate.z);
		});
	}
	
	var addVideoScreen = (obj, group, scene) => {
		
		add(obj).then(result => {
				
			const model = result.scene;
			const screen = model.getObjectByName("Screen");
			const display = model.getObjectByName("Display");
			const stand = model.getObjectByName("Stand");
			
			screen.material = screenGlass;
			display.material = blackPlatic;
			stand.material = blackMetal;
			
			model.name = obj.name;
			group.add(model)
			scene.add(group);
	
			model.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
			model.rotation.set(obj.rotate.x, obj.rotate.y, obj.rotate.z);
		});
	}
	
	var addPodium = (obj, group, scene) => {
	
		const wood = new THREE.MeshStandardMaterial({color: "#895F3E"});
		
		add(obj).then(result => {
				
			const model = result.scene;
			const stand = model.getObjectByName("Stand");
			
			stand.material = wood;
			stand.material.map = textureWood;
			
			model.name = obj.name;
			group.add(model)
			scene.add(group);
	
			model.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
			model.rotation.set(obj.rotate.x, obj.rotate.y, obj.rotate.z);
		});
	}
	
	var addStageUnit = (obj, group, scene) => {
		
		add(obj).then(result => {
				
			const model = result.scene;
			const stage = model.getObjectByName("Stage");
			const top = model.getObjectByName("Top");
			
			const stageColor = new THREE.MeshLambertMaterial({color: "#000000"});
			const topColor = new THREE.MeshLambertMaterial({color: "#3F3F3D"});
			
			stage.material = stageColor;
			top.material = topColor;
			
			model.name = obj.name;
			group.add(model)
			scene.add(group);
	
			model.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
			model.rotation.set(obj.rotate.x, obj.rotate.y, obj.rotate.z);
		});
	}
	
	return {add, addTable, addChair, addRoom, addPortableBar, addVideoScreen, addPodium, addStageUnit};
}