import React, {useState}  from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, doc, collection, addDoc, setDoc} from 'firebase/firestore';

// Components
import Alert from '../components/cards/CardAlert';
import RoomSelect from '../components/controls/RoomSelect.js';

function Page() {
	
	const db = getFirestore(fire);
	const history = useHistory();
	const {propertyId} = useParams();
	const {eventId} = useParams();
	const [name, setName] = useState("");
	const [room, setRoom] = useState("Ballroom");
	const [alertState, setAlertState] = useState("");
	
	const handleSubmit = async (e) => {
		
		e.preventDefault();
		
		const scene = {name: name, room: room};
		
		const groupFrontLeft = {name: "front left", pos: {x: 0, y: 0, z: 0}};
		const groupFront = {name: "front", pos: {x: 0, y: 0, z: 0}};
		const groupFrontRight = {name: "front right", pos: {x: 0, y: 0, z: 0}};
		
		const groupLeft = {name: "left", pos: {x: 0, y: 0, z: 0}};
		const groupCenter = {name: "center", pos: {x: 0, y: 0, z: 0}};
		const groupRight = {name: "right", pos: {x: 0, y: 0, z: 0}};
		
		const groupBackLeft = {name: "back left", pos: {x: 0, y: 0, z: 0}};
		const groupBack = {name: "back", pos: {x: 0, y: 0, z: 0}};
		const groupBackRight = {name: "back right", pos: {x: 0, y: 0, z: 0}};
		
		async function asyncFire() {
			
			try {
			
				const docRef = await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes'), scene);
				
				// front left
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "front left"), groupFrontLeft);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "front left", "models"), {});
			
				// front
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "front"), groupFront);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "front", "models"), {});
			
				// front right
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "front right"), groupFrontRight);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "front right", "models"), {});
						
				//  left
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "left"), groupLeft);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "left", "models"), {});
			
				// 
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "center"), groupCenter);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "center", "models"), {});
			
				//  right
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "right"), groupRight);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "right", "models"), {});
			
				// back left
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "back left"), groupBackLeft);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "back left", "models"), {});
			
				// back
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "back"), groupBack);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "back", "models"), {});
			
				// back right
				await setDoc(doc(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "back right"), groupBackRight);
				await addDoc(collection(db, 'properties', propertyId, 'events', eventId, 'scenes', docRef.id, "groups", "back right", "models"), {});
		
				history.push("/scene-success");
			
			} catch (e) {
				
				setAlertState("error");
			}
		
		}
		asyncFire();
	}
	
	return (
	<main className="main-container container">
	<div className="row mt-4 justify-content-center">
		<div className="col-6">
			
			{(alertState === "success") &&
				<Alert type="success">
					Success! View new scene from <Link exact to="/dashboard">Dashboard</Link>.
				</Alert>
			}
			
			{(alertState === "warning") &&
				<Alert type="warning">
					Warning!
				</Alert>
			}
			
			{(alertState === "danger") &&
				<Alert type="danger">
					Error!
				</Alert>
			}
			
			<h1>New Scene</h1>
			<form onSubmit={handleSubmit}>
				<div className="form-group mb-4">
					<label htmlFor="name" className="form-label">Name:</label>
					<input id="name" className="form-control" type="text" value={name} onChange={({target}) => setName(target.value)} />
				</div>
				<RoomSelect propertyId={propertyId} room={room} setRoom={setRoom} />
				<hr />
				<button type="button" onClick={() => history.goBack()} className="btn btn-primary mt-3">Cancel</button>
				<button type="submit" className="btn btn-primary mt-3 ms-3">Submit</button>
			</form>
		</div>
	</div>
	</main>
	);
}
export default Page;
