import React, {useEffect, useRef} from 'react';
import styles from './Styles.module.scss';
import RoomJson from '../../json/Room.js';

const ObjectAlign = (props) => {

	const roomRef = useRef(null);
	
	useEffect(() => {
	
		if (props.sceneInfo) {
			roomRef.current = RoomJson.[props.propertyId].find(obj => obj.name === props.sceneInfo.room);
		}
	})
	
	var handleChange = (e) => {
		
			let target = e.target.value;
			let x = roomRef.current.width.x / 2;
			let z = roomRef.current.width.z / 2;
			
			let group = props.group;
			
			props.setObjAlign(target);
			
			switch(target) {
				
				case "front left":
					props.setPos(prevState => ({x: -x, y: props.pos.y, z: -z}));
					group = "front left";
				break;
				
				case "front":
					props.setPos(prevState => ({x: 0, y: props.pos.y, z: -z}));
					group = "front";
				break;
			
				case "front right":
					props.setPos(prevState =>  ({x: x, y: props.pos.y, z: -z}));
					group = "front right";
				break;
				
				case "left":
					props.setPos(prevState => ({x: -x, y: props.pos.y, z: 0}));
					group = "left";
				break;

				case "center":
					props.setPos(prevState => ({x: 0, y: props.pos.y, z: 0}));
					group = "center";
				break;
				
				case "right":
					props.setPos(prevState => ({x: x, y: props.pos.y, z: 0}));
					group = "right";
				break;
				
				case "back left":
					props.setPos(prevState => ({x: -x, y: props.pos.y, z: z}));
					group = "back left";
				break;
				
				case "back":
					props.setPos(prevState => ({x: 0, y: props.pos.y, z: z}));
					group = "back";
				break;

				case "back right":
					props.setPos(prevState => ({x: x, y: props.pos.y, z: z}));
					group = "back right";
				break;
				
				default:
					props.setPos(prevState => ({x: 0, y: props.pos.y, z: 0})); 	
			}
			
			props.setGroupSelect(group);
			
			return target;
	}
	
	return (
	<>
	<div className={`container pt-2 pb-3 ${styles.objectAlign}`}>
		<div className="d-flex justify-content-between">
			<div>
				<input type="radio" name="objAlign" id="objectFrontLeft" checked={props.objAlign === "front left"} value="front left" onChange={handleChange} />
				<label className={`label ${styles.frontLeft}`} htmlFor="objectFrontLeft"><span>Align front left</span></label>
			</div>
			<div>
				<input type="radio" name="objAlign" id="objectFront" checked={props.objAlign === "front"} value="front" onChange={handleChange} />
				<label className={`label ${styles.front}`} htmlFor="objectFront"><span>Align front</span></label>
			</div>
			<div>
				<input type="radio" name="objAlign" id="objectFrontRight" checked={props.objAlign === "front right"} value="front right" onChange={handleChange} />
				<label className={`label ${styles.frontRight}`} htmlFor="objectFrontRight"><span>Align front right</span></label>
			</div>
		</div>
		
		<div className="d-flex mt-2 justify-content-between">
			<div>
				<input type="radio" name="objAlign" id="objectLeftCenter" checked={props.objAlign === "left"} value="left" onChange={handleChange} />
				<label className={`label ${styles.left}`} htmlFor="objectLeftCenter"><span>Align left</span></label>
			</div>
			<div>
				<input type="radio" name="objAlign" id="objectCenter" checked={props.objAlign === "center"} value="center" onChange={handleChange} />
				<label className={`label ${styles.center}`} htmlFor="objectCenter"><span>Align center</span></label>
			</div>
			<div>
				<input type="radio" name="objAlign" id="objectRightCenter" checked={props.objAlign === "right"} value="right" onChange={handleChange} />
				<label className={`label ${styles.right}`} htmlFor="objectRightCenter"><span>Align right</span></label>
			</div>
		</div>
		
		<div className="d-flex mt-2 justify-content-between">
			<div>
				<input type="radio" name="objAlign" id="objectBackLeft" checked={props.objAlign === "back left"} value="back left" onChange={handleChange} />
				<label className={`label ${styles.backLeft}`} htmlFor="objectBackLeft"><span>Align back left</span></label>
			</div>
			<div>
				<input type="radio" name="objAlign" id="objectBack" checked={props.objAlign === "back"} value="back" onChange={handleChange} />
				<label className={`label ${styles.back}`} htmlFor="objectBack"><span>Align back</span></label>
			</div>
			<div>
				<input type="radio" name="objAlign" id="objectBackRight" checked={props.objAlign === "back right"} value="back right" onChange={handleChange} />
				<label className={`label ${styles.backRight}`} htmlFor="objectBackRight"><span>Align back right</span></label>
			</div>
		</div>
	</div>
	<p className="label">Alignment: {props.objAlign}</p>
	</>	
	)
}
export default ObjectAlign;
