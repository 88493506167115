import React, { useState, useEffect} from 'react';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore';
// getDoc, doc

// Components
import NavPlaygrounds from '../components/navs/NavPlaygrounds';


function Home() {

	/*** USERS ***/
	const db = getFirestore(fire);
	
	// w/ Promise
	const [users, setUsers] = useState(null);
	
	// w/ Promise
	const [events, setEvents] = useState([]);
	const [events2, setEvents2] = useState([]);
	const [events3, setEvents3] = useState([]);
	
	const [event, setEvent] = useState([]);
	
	/*** EVENTS & SCENES ***/
	const [eventThenScenes, setEventThenScenes] = useState([])
 	const [eventsThenScenes, setEventsThenScenes] = useState([])
 	const [eventsThenScenes2, setEventsThenScenes2] = useState([])
 	
	useEffect(() => {
		
		/*** USERS ***/
		
		// Promise with Errors Example
		/*
		const getUsersPromise = new Promise((resolve, reject) => {
		
			const col = collection(db, 'users');
			const snapshot = getDocs(col);
			
			resolve(snapshot);
			reject(new Error("User not Logged in"));
		});
		
		getUsersPromise.then(snapshot => {
		 	
		 	const list = snapshot.docs.map(doc => doc.data());
		 	
		 	setUsers(list)
		}).catch(err => console.log(err.message)); // or console.log(err to see message and actual error)
		*/
		
		// w/ Promise - Alt
		function getUsers(collName) {
			
			return new Promise((resolve, reject) => {
			
				const col = collection(db, collName);
				const snapshot = getDocs(col);
			
				resolve(snapshot);
				reject(new Error("User not Logged in"));
			
			});
		}
		
		
		/*** EVENTS ***/
		
		// Just Events
		function getEvents(collName) {
			
			return new Promise((resolve, reject) => {
			
				const col = collection(db, collName);
				const snapshot = getDocs(col);
			
				resolve(snapshot);
				reject(new Error("User not Logged in"));
			
			});
		
		}	
		
		
		/*** SCENES ***/
		
		// Just scences
		function getScenes(event) {
		
			return new Promise((resolve, reject) => {
			
				const col = collection(db, 'events', event, "scenes");
				const snapshot = getDocs(col);
				
				resolve(snapshot);
			});
		
		}
		
		// Events then scenes
		function getEventsThenScenes(collName) {
			
			return new Promise((resolve, reject) => {
			
				const col = collection(db, collName);
				const snapshot = getDocs(col);
			
				resolve(snapshot);
				reject(new Error("User not Logged in"));
			
			});
		}
		
		
		// Event then scenes
		function getEventThenScenes(collName) {
			
			return new Promise((resolve, reject) => {
				
				const col = doc(db, 'events', "GgI4XhRMFvYce6xBP9IV");
				const snapshot = getDoc(col);
				
				resolve(snapshot);
			});
		}
		
	
		// INIT
		function init() {
		
			/*** USERS ***/
			
			getUsers('users').then(snapshot => {
		 	
				const list = snapshot.docs.map(doc => doc.data());
		
				setUsers(list);
			});
			
			
			/*** EVENTS ***/
		
			// Just events
			getEvents('events').then(snapshot => {
	
				const list = snapshot.docs.map(doc => doc.data());
	
				setEvents(list);
			});
			
			
			// Events then scenes
			getEventsThenScenes('events').then(snapshot => {
		 	
		 		const list = snapshot.docs.map(doc => ({
					id: doc.id, ...doc.data() 
				}));
		 		
		 		setEvents2(list);
		 		
		 		getScenes(list[0].id).then(snapshot => {
		 		
		 			const list = snapshot.docs.map(doc => doc.data());
		 			
		 			setEventsThenScenes(list);
				});
			});
			
			
			
			// NEW Events then scenes
			getEventsThenScenes('events').then(snapshot => {
				
				var getScenesTest = (id) => {
				
				 	var value;
				
					if (id === "GgI4XhRMFvYce6xBP9IV") { 
					
						value =	[
							{id: "9A0GQRNC16oriwQGaZR9", name: "troy"},
							//{id: "9A0GQRNC16oriwQGaZR92", name: "Sven"}
						];
					}
					
					if (id === "TGv2CX043LaOiifFSNur") { 
						
						value = [
							{id: "A0R9jRixg11FGuE7RAHJ", name: "josep"},
							//{id: "A0R9jRixg11FGuE7RAHJ2", name: "Dave"}
						];
					}
					
					return value;
				}
			
				const events = snapshot.docs.map(doc => {
					
					var event = {id: doc.id, ...doc.data()};
					var scenes = getScenesTest(event.id);
					
					scenes.map( (scene) => {
						return scene;
					});
					
					console.log(scenes);
					//console.log("proto length: " + scenes.length);
					//console.log("proto name: " + scenes[0].name);
					
					event.sceneList = scenes;
						
					return event;
				});
		 		
		 		setEvents3(events);
		 		
				// REAL		 		
		 		const events2 = snapshot.docs.map(doc => {
		 		
		 			var event = {id: doc.id, ...doc.data()};

					getScenes(event.id).then(snapshot => {
					
						const scenes = snapshot.docs.map( doc => {
							var scene = {id: doc.id, ...doc.data()}
							return scene;
						});
						
						console.log(scenes);
						//console.log("fire length: " + scenes.length);
						//console.log("fire name: " + scenes[0].name);
						
						event.sceneList = scenes;
					});
					
					return event;
		 		});
		 		
		 		setEventsThenScenes2(events2);
			});
			
			
			// Event then scenes
			getEventThenScenes('events').then(snapshot => {
			
				const list = {id: snapshot.id, ...snapshot.data()}
				
				setEvent(list)
		 	
		 		getScenes(list.id).then(snapshot => {
		 		
		 			const list = snapshot.docs.map(doc => doc.data());
		 			setEventThenScenes(list)
				});
			});
			
			
			/*** SCENES ***/
			
			
			
			
		}
		
		init()
	
  	}, [db])


	return (
	<main className="main-container container mt-3">
		<h1>Firestore: Promise</h1>
		
		<hr />
		
		{/* USERS */}
		
		<div className="mb-3">
			<h3>Users w/ Promise - [users, setUsers]</h3>
			<ul>
			{users && users.map((p, i) => (
				<li key={i}>User: {p.firstName}</li>
			))}
			</ul>
		</div>
		
		<hr />
		
		{/* EVENTS */}
		
		<div className="mb-3">
			<h3>Events - [events, setEvents]</h3>
			
			<ul>
			{events.map((e, i) => (
				<li key={i}>{e.name} : {e.name}</li>
			))}
			</ul>
		</div>
		
		<hr />
		
		<h2>EVENTS > SCENES</h2>
	
		<hr />
		
		<div className="mb-3">
			<h2>Events Then Scenes - [eventsThenScenes, setEventsThenScenes]</h2>
			
			<h4>Events:</h4>
			{events2.map((e, i) => (
				<li key={i}>Event : {e.name}</li>
			))}
			
			<h4>Scenes:</h4>
			<ul>
			{eventsThenScenes.map((e, i) => (
				<li key={i}>Scene : {e.name}</li>
			))}
			</ul>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2><strong> Prototype: </strong> Events Then Scenes - [eventsThenScenes, setEventsThenScenes]</h2>
	
			<h4>Events & Scenes:</h4>
			{events3.map((e, i) => (
				<li key={i}>Event: {e.name} 
					<ul>
						{e.sceneList && e.sceneList.map((s, i) => (
							<li key={i}>Scene: {s.name}</li>
						))}
					</ul>
				</li>
			))}
		</div>

		<hr />
		
		<div className="mb-3">
			<h2><strong> Firestore: </strong> Events Then Scenes - [eventsThenScenes, setEventsThenScenes]</h2>
			
			<h4>Events & Scenes:</h4>
			{eventsThenScenes2.map((e, i) => (
				<li key={i}>Event: {e.name}
					<ul>
						{e.sceneList && e.sceneList.map((s, i) => (
							<li key={i}>Scenes: {s.name}</li>
						))}
					</ul>
				</li>
			))}


			
			<ul>
			
			{/* eventsThenScenes2[0] && eventsThenScenes2[0].sceneList[0].name */}
			
			{/*eventsThenScenes2.sceneList.map((e, i) => (
				<li key={i}>Scene : {e.name}</li>
			))*/}
			
			
			{/*eventsThenScenes2.map((e, i) => (
			
			<ul>
				<li key={i}>{e.name}
					
					
					{e.sceneList.map((s, i) => (
					<ul>
						<li key={i}>Scene : {s.name}</li>
					</ul>
					))}
					
					
					<ul>
						<li key={i}>Scene : {e.sceneList[0].name}</li>
					</ul>
					
				
				</li>
			
			</ul>
				
			))*/}
			</ul>
		</div>
		
		<hr />
		
		
		<div className="mb-3">
			<NavPlaygrounds />
			<h3>Event Then Scenes - [eventThenScenes, setEventThenScenes]</h3>
			<p>Event: {event.name}</p>
			
			<ul>
			
			{eventThenScenes.map((e, i) => (
				<li key={i}>Scene : {e.name} </li>
			))}
			
			</ul>
		</div>
		
		
	</main>
  );
}

export default Home;
