import React, {useState, useEffect}  from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, getDoc, doc, collection, getDocs} from 'firebase/firestore';

function Page() {
	
	const db = getFirestore(fire);
	
	let { propertyId } = useParams();
	let { eventId } = useParams();
	const [scenes, setScenes] = useState([]);
	let history = useHistory();
	
	const [name, setName] = useState("");
	const [desc, setDesc] = useState("");
	const [type, setType] = useState("");
	const [guest, setGuest] = useState(0);

	const handleSubmit = async (e) => {
		e.preventDefault();
	}
	
	useEffect(() => {
	
		async function getEvent(db) {
	
			const colEvents = doc(db, "properties", propertyId,  'events', eventId);
			const snapshot = await getDoc(colEvents);
			
			if (snapshot.exists()) {
				setName(snapshot.data().name);
				setDesc(snapshot.data().desc);
				setType(snapshot.data().type);
				setGuest(snapshot.data().guest);
			}
		}	
		getEvent(db);
		
		const scenesCol = collection(db, "properties", propertyId, "events", eventId, 'scenes');
		
		const getScenes = async () => {
			const snapshot =  await getDocs(scenesCol);
			const scenesAll = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
			
			setScenes(scenesAll);
		}
		getScenes();
		
	}, [db, propertyId, eventId])
	
	return (
	<main className="main-container container mt-3">
	<div className="row mt-4 justify-content-center-off">
		<div className="col-md-8">
			<h1>Edit Event</h1>
			
			<form className="mb-4" onSubmit={handleSubmit}>
				<div className="form-group mb-4">
					<label htmlFor="name" className="form-label">Name:</label>
					<input id="name" className="form-control" type="text" value={name} onChange={({target}) => setName(target.value)} />
				</div>
				<div className="form-group mb-4">
					<label htmlFor="desc" className="form-label">Description:</label>
					<input id="desc" className="form-control" type="text" value={desc} onChange={({target}) => setDesc(target.value)} />
				</div>
				<div className="row mb-4">
					<label htmlFor="type" className="col-sm-3 col-form-label">Type:</label>
					<div className="col-sm-9">
						<select id="type" value={type} onChange={(e) => setType(e.value)} className="form-select" style={{width: "200px"}}>
							<option value="Wedding">Wedding</option>
							<option value="meeting">Business meeting</option>
							<option value="Bar mitzvah">Bar mitzvah</option>
							<option value="Bat mitzvah">Bat mitzvah</option>
							<option value="Birthday party">Birthday party</option>
						</select>
					</div>
				</div>
				<div className="row mb-4">
					<label htmlFor="guest" className="col-6 form-label">No. of guest:</label>
					<div className="col-6">
						<input id="guest" className="form-control" style={{width: "80px"}} type="number" value={guest} size="4"
						onChange={ ({target}) => setGuest(target.value) } 
						 />
					</div>
				</div>
				<hr />
				<button type="button" onClick={() => history.goBack()}  className="btn btn-primary mt-3">Cancel</button>
				<button type="submit" className="btn btn-primary mt-3 ms-3">Save</button>
			</form>
		</div>
		<div className="col-md-4">
			<h2>Scenes</h2>
			<ul>
				{scenes &&
					scenes.map((scene, i) => (
						<li key={i}>{scene.name} | <Link to={`/scene/${propertyId}/${eventId}/${scene.id}`}>Edit</Link></li>
					))
				}
			</ul>
		</div>
	</div>
	</main>
	);
}
export default Page;
