const Checkbox = (props) => {

 	var handleChange = () => { props.setChecked(!props.checked) }
 	
	return (
	<div className="form-check">
		<input type="checkbox" id={props.fireData} className="form-check-input" 
		onChange={handleChange} checked={props.checked} />
		<label className="form-check-label" htmlFor={props.fireData}>{props.label}</label>
	</div>
	)
}
export default Checkbox;