import * as THREE from 'three';


export function useLights() {
	
	const getLights = (scene) => {
		
		const light1 = new THREE.PointLight(0XFFFFE7, .6); //.8
		const light2 = new THREE.PointLight(0Xffffff, .6); //.8
		const light3 = new THREE.PointLight(0Xffffff, .3); //.8
	
		const directionalLight = new THREE.DirectionalLight( 0xffffff, .5);
		directionalLight.position.set(5, 4, 5);
	
		light1.position.set(-6, 3, 0);
		light2.position.set(6, 3, 0);
		light3.position.set(8, 4, 0);
	
		/*
		light1.add(new THREE.Mesh(
			new THREE.SphereGeometry(1, 10, 10),
			new THREE.MeshBasicMaterial({color: 0Xffffff})
		));
	
		light2.add(new THREE.Mesh(
			new THREE.SphereGeometry(1, 10, 10),
			new THREE.MeshBasicMaterial({color: 0Xffffff})
		));
	
		light3.add(new THREE.Mesh(
			new THREE.SphereGeometry(1, 10, 10),
			new THREE.MeshBasicMaterial({color: 0Xffffff})
		));
		*/
	
		scene.add(light1);
		scene.add(light2);
		//scene.add(light3);
		scene.add(directionalLight);
	};
	
	const getBallroomLights = (scene) => {
		
		const int = .2
		
		const light1 = new THREE.PointLight(0XFFFFE7, int);
		const light2 = new THREE.PointLight(0XFFFFE7, int);
		const light3 = new THREE.PointLight(0XFFFFE7, int);
		const light4 = new THREE.PointLight(0XFFFFE7, int);
		const light5 = new THREE.PointLight(0XFFFFE7, int);
		
		const ambientLight = new THREE.AmbientLight(0xffffff, .5);
		
		const directionalLight = new THREE.DirectionalLight( 0xffffff, .3);
		directionalLight.position.set(9, 7, 9);
		
		//directionalLight.position.set(5, 4, 5);
		
		light1.position.set(-7, 4, -7);
		light2.position.set(7, 4, -7);
		light3.position.set(7, 4, 7);
		light4.position.set(-7, 4, 7);
		light5.position.set(0, 4, 0); // Center
		
		/*
		light1.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0XFF1400}) // R
        ));
		
		light2.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0X0DFF00}) // G
        ));
        
        light3.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0X0519FF}) // B
        ));
        
        light4.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0X0ffffff}) // B
        ));
        
        light5.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0X0ffffff}) // B
        ));
     	*/
		
		//scene.add(light1);
		//scene.add(light2);
		//scene.add(light3);
		//scene.add(light4);
		scene.add(light5);
		scene.add(ambientLight);
		scene.add(directionalLight);
	};
	
	const getJrBallroomLights = (scene) => {
		
		const int = .2;
		
		const light1 = new THREE.PointLight(0XFFFFE7, int);
		const ambientLight = new THREE.AmbientLight(0xffffff, .5);
		
		const directionalLight = new THREE.DirectionalLight( 0xffffff, .3);
		directionalLight.position.set(4, 4, 5);
		
		light1.position.set(0, 3, 0);
		
		/*
		light1.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0Xffffff})
        ));
		
		light2.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0Xffffff})
        ));
        */
        
		scene.add(light1);
		scene.add(ambientLight);
		scene.add(directionalLight);
	};
	
	const getLargeMeetingroomLights = (scene) => {
		
		const light1 = new THREE.PointLight(0Xffffff, .4);
		const light2 = new THREE.PointLight(0Xffffff, .4);
		const ambientLight = new THREE.AmbientLight(0xffffff, .5);
		
		const directionalLight = new THREE.DirectionalLight( 0xffffff, .9);
		directionalLight.position.set(7, 4, 3.5);
		
		light1.position.set(-5, 3, 0);
		light2.position.set(5, 3, 0);
		
		/*
		light1.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0Xffffff})
        ));
		
		light2.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0Xffffff})
        ));
        */
        
		scene.add(light1);
		scene.add(light2);
		scene.add(ambientLight);
		scene.add(directionalLight);
	};
	
	const getSmallMeetingroomLights = (scene) => {
		
		const light1 = new THREE.PointLight(0Xffffff, .4);
		const ambientLight = new THREE.AmbientLight(0xffffff, .5);
		
		const directionalLight = new THREE.DirectionalLight( 0xffffff, .9);
		directionalLight.position.set(4, 2.5, 3);
		light1.position.set(0, 3, 0);
		
		light1.position.set(4, 2.5, 3);
		
		/*
		light1.add(new THREE.Mesh(
            new THREE.SphereGeometry(1, 10, 10),
            new THREE.MeshBasicMaterial({color: 0Xffffff})
        ));
        */
     
		scene.add(light1);
		scene.add(ambientLight);
		scene.add(directionalLight);
	};
	
	return {getLights, getBallroomLights, getJrBallroomLights, getLargeMeetingroomLights, getSmallMeetingroomLights};
} 
