import React, {useState, useRef} from 'react';

const Position = (props) => {
	
	const [checked, setChecked] = useState(false);
 	const checkedRef = useRef(checked);

	var handleStage = (e) => {
			
			setChecked(!checked);
			checkedRef.current = !checkedRef.current;
			
			if (checkedRef.current) {
				props.setPos(
					prevState => {
						return {...prevState, y: .8 };
					}
				)
			} else {
				props.setPos(
					prevState => {
						return {...prevState, y: 0 };
					}
				)
			}
	}

	return (
	<>
	<div className="mb-3 row">
		<label htmlFor="posX" className="col-6 form-label">x:</label>
		<div className="col-6">
			<input id="posX" className="form-control form-control-sm" type="number" value={props.pos.x} size="6" 
				onChange={e => {
					props.setPos(
						prevState => {
							return {...prevState, x: e.target.value };
						}
					)
				}
			} />
		</div>
	</div>
	{/* 
	<div className="mb-3 row">
		<label htmlFor="posY" className="col-6 form-label">y:</label>
		<div className="col-6">
			<input id="posY" className="form-control form-control-sm" type="number" value={props.pos.y} size="6" 
				onChange={e => {
					props.setPos(
						prevState => {
							return {...prevState, y: e.target.value };
						}
					)
				}
			} />
		</div>
	</div>
	*/}
	{(props.type !== "group") &&
	<div className="mb-3 row">
		<label htmlFor="posY" className="col-6 form-label">y:</label>
		<div className="col-6">
			<input className="form-check-input"
			onChange={handleStage}
			type="checkbox" id="posY" checked={checked} />
			<label className="form-check-label ms-2" htmlFor="posY">Stage</label>
		</div>
	</div>
	}
	<div className="mb-3 row">
		<label htmlFor="posZ" className="col-6 form-label">z:</label>
		<div className="col-6">
			<input id="posZ" className="form-control form-control-sm" type="number" value={props.pos.z} size="6"
				onChange={e => {
					props.setPos(prevState => {
						return {...prevState, z: e.target.value };
					})
				}
			} />
		</div>
	</div>
	</>
	)
}
export default Position;
