import React, { useState, useEffect, useContext, useRef} from 'react';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore';
// getDoc, doc

//import {getAuth, signInWithCustomToken} from 'firebase/auth';

// Components
import NavPlaygrounds from '../components/navs/NavPlaygrounds';

// Context
import {UserContext} from '../context/authContext';

function Home() {

	/* TEST Reducer
	const [userInput, setUserInput] = useReducer( (state, newState) => (
		{...state, ...newState}), {
			objSelect: ""
		}
	);
	*/
	
	/*** USERS ***/
	const db = getFirestore(fire);
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(null);
	
	const defaultUserContext = useContext(UserContext);
 	const userRef = useRef(defaultUserContext);
 	
 	/*** EVENTS ***/
 	//const [events, setEvents] = useState([])
 	const [event, setEvent] = useState([])
 	const [firstHalf, setFirstHalf] = useState([])
	const [secondHalf, setSecondHalf] = useState([])
 	
 	/*** SCENE ***/
 	const [scene, setScene] = useState([]);
 	const [scenes, setScenes] = useState([]);
 	//const [cube, setCube] = useState([]);
 	const [cubes, setCubes] = useState([]);
 	
	useEffect(() => {
		
		async function getUsers(db) {
	
			const col = collection(db, 'users');
			const snapshot = await getDocs(col);
		
			const list = snapshot.docs.map(doc => doc.data());
			
			setUsers(list)
		}
	
		async function getUser(db) {
	
			const col = doc(db, 'users', "QZj4iOsdDaBxj2beWRyG");
			const snapshot = await getDoc(col);
			
			if (snapshot.exists()) {
				setUser({id: snapshot.id, ...snapshot.data()})
			}
		}	
		getUser(db);
		
		async function getEvents(db) {
		
			const col = collection(db, 'events');
			const snapshot = await getDocs(col);
		
			const list = snapshot.docs.map(doc => doc.data());
			const half = Math.ceil(list.length / 2);  
			setFirstHalf(list.slice(0, half));
			setSecondHalf(list.slice(-half));
		}
		
		async function getEvent(db) {
			
			const col = doc(db, 'events', "GgI4XhRMFvYce6xBP9IV");
			
			const snapshot = await getDoc(col);
			
			if (snapshot.exists()) {
				setEvent(snapshot.data());
			}
		}
		
		async function getScenes(db, event) {
	
			const col = collection(db, 'events', event, "scenes");
			const snapshot = await getDocs(col);
		
			const list = snapshot.docs.map(doc => doc.data());
			
			setScenes(list)
		}
		
		async function getScene(db) {
	
			const col = doc(db, 'events', 'GgI4XhRMFvYce6xBP9IV', 'scenes', "9A0GQRNC16oriwQGaZR9");
			const snapshot = await getDoc(col);
			
			if (snapshot.exists()) {
				setScene(snapshot.data());
			}
		}
		
		async function getCubes(db) {
	
			const col = collection(db, 'events', 'GgI4XhRMFvYce6xBP9IV', 'scenes', "9A0GQRNC16oriwQGaZR9", "cubes");
			const snapshot = await getDocs(col);
			
			const list = snapshot.docs.map(doc => doc.data());
			setCubes(list);
		}
		
		/*
		async function getCube(db) {
	
			let col = doc(db, 'scenes', "oGzAJuBob8Se2iG04WtU", "cubes", "sE8jkZRJJO2WlJSA7ZCT");
			
			const snapshot = await getDoc(col);
			
			if (snapshot.exists()) {
				setCube(snapshot.data());
			}
		}
		*/	


		// INIT
		function init() {
		
			getUsers(db);
			getUser(db);
			
			getEvents(db);
			getEvent(db);
			
			getScenes(db, "GgI4XhRMFvYce6xBP9IV");	
			getScene(db);
			
			getCubes(db);
			//getCube(db);
		}
		
		init()
	
  	}, [db])


	return (
	<main className="main-container container mt-3">
		<NavPlaygrounds />
		<h1>Firestore</h1>
		
		<hr />
		
		<h2>Environmental Variables</h2>
		<p>API KEY: {process.env.REACT_APP_API_KEY}</p>
		
		<hr />
		
		<div className="mb-3">
			<h2>Users [users, setUsers]</h2>
			<ul>
			{users && users.map((p, i) => (
				<li key={i}>User: {p.firstName}</li>
			))}
			</ul>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2>User [user, setUser]</h2>
			<p>First name: {user && user.firstName}</p>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2>User [userContext]</h2>
			<p>First name: {userRef.current.firstName}</p>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2>Events [events, setEvents]</h2>
			<div className="row">
				<div className="col-md-6">
					<ul>
					{firstHalf.map((e, i) => (
						<li key={i}>{e.name} : {e.desc}</li>
					))}
					</ul>
				</div>
	
				<div className="col-md-6">
					<ul>
					{secondHalf.map((e, i) => (
						<li key={i}>{e.name} : {e.desc}</li>
					))}
					</ul>
				</div>
			</div>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2>Event [event, setEvent]</h2>
			<p>Event: {event.desc}</p>
		</div>	
		
		<hr />
		
		<div className="mb-3">
			<h2>Scenes [scenes, setScenes]</h2>
			<ul>
			{scenes && scenes.map((p, i) => (
				<li key={i}>Scene: {p.name}</li>
			))}
			</ul>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2>Scene [scene, setScene]</h2>
			<p>Scene: {scene.name}</p>
		</div>
		
		<hr />
		
		<div className="mb-3">
			<h2>Cubes [cubes, setCubes]</h2>
			<ul>
				{cubes && cubes.map((p, i) => (
					<li key={i}>Event: {p.name}</li>
				))}
			</ul>
		</div>
		
	</main>
  );
}

export default Home;
