import {NavLink } from 'react-router-dom';

function Nav() {
	return (
	<div className="row navDesign justify-content-center"> 
		<ul className="nav nav-pills justify-content-center">
			<li className="nav-item">
				<NavLink activeClassName="active" exact to="/playgrounds" className={`nav-link`}>Firestore</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="active" to="/playgrounds/promise" className={`nav-link`}>Firestore: Promise</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="active" to="/playgrounds/events" className={`nav-link`}>Firestore: Events > Scenes</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="active" to="/playgrounds/cube" className={`nav-link`}>Cube (Threejs)</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="active" to="/playgrounds/counter" className={`nav-link`}>Counter</NavLink>
			</li>
		</ul>
	</div>
	);
}
export default Nav;
