// Components
import NavDocs from '../components/navs/NavDocs';


const ConverterInches =(props => {

	let m = (props.inches * 0.0254).toPrecision(4);
	let cm = (props.inches * 25.4).toPrecision(4);
	
	return(
		<tr>
			<th scope="row">{props.th}</th>
			<td>{props.inches}&#8221;</td>
			<td>{m}</td>
			<td>{cm}</td>
		</tr>	
	)

})


const ConverterFeet =(props => {

	let m = (props.feet/3.2808).toPrecision(4);
	let cm = (props.feet * 304.8).toPrecision(7);
	
	return(
		<tr>
			<th scope="row">{props.th}</th>
			<td>{props.feet}&#8217;</td>
			<td>{m}</td>
			<td>{cm}</td>
		</tr>	
	)

})


function Page() {
	return (
	<main className="main-container container mt-3">
	<NavDocs />
	<div className="row">
		<div className="col-12">
	
			<h3>Room Dimensions</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterFeet th="Average ballroom width" feet={90} />
					<ConverterFeet th="Average ballroom depth" feet={84} />
					<ConverterFeet th="Average ballroom ceiling height" feet={15.5} />
					<ConverterFeet th="Jr ballroom width" feet={74} />
					<ConverterFeet th="Jr ballroom depth" feet={42} />
					<ConverterFeet th="Meeting room A width" feet={50} />
					<ConverterFeet th="Meeting room A width" feet={25} />
					<ConverterFeet th="Meeting room B width" feet={26} />
					<ConverterFeet th="Meeting room B depth" feet={22} />
					<ConverterFeet th="Meeting room ceiling height" feet={9.5} />
				</tbody>
			</table>
	
			<h3>Colors</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Hex</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>Black</th>
						<td>#000000</td>
					</tr>
					<tr>
						<th>White</th>
						<td>#ffffff</td>
					</tr>
					<tr>
						<th>Cream</th>
						<td>#f3edd5</td>
					</tr>
				</tbody>
			</table>
	
			<h3>Room Doors</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Door Width" inches={30} />
					<ConverterInches th="Door Height" inches={80} />
					<ConverterInches th="Depth" inches={2} />
				</tbody>
			</table>
			
			<h2>Chairs</h2>
	
			<h3>Lancaster Chair</h3>
			<p><a href="https://www.webstaurantstore.com/lancaster-table-seating-gray-fabric-crown-back-stackable-banquet-chair-with-silver-vein-frame/164BNQCRGRY.html">Ref</a></p>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Width" inches={17.333} />
					<ConverterInches th="Depth" inches={21.688} />
					<ConverterInches th="Height" inches={37.75} />
					<ConverterInches th="Seat Width" inches={15.5} />
					<ConverterInches th="Seat Depth" inches={15.75} />
					<ConverterInches th="Seat Height" inches={19} />
					<ConverterInches th="Seat Thickness" inches={2} />
				</tbody>
			</table>
			
			<h3>Chiavari Chair (NEW)</h3>
			<p><a href="https://www.webstaurantstore.com/lancaster-table-seating-black-chiavari-chair/164CHIAVBLK.html">Ref</a></p>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Width" inches={15.5} />
					<ConverterInches th="Depth" inches={15.75} />
					<ConverterInches th="Height" inches={35} />
					
					<ConverterInches th="Seat Width" inches={15.5} />
					<ConverterInches th="Seat Depth" inches={15.5} />
					<ConverterInches th="Seat Height" inches={16} />
					<ConverterInches th="Seat Thickness" inches={1.5} />
				</tbody>
			</table>
			
			<h2>Meeting Tables</h2>
			
			<h3>Square Table <small>- 18 x 60 inch</small></h3>
			<p><a href="https://www.forbesindustries.com/product/revolution-table-18-60-training/">Ref</a></p>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Height' inches={29} />
					<ConverterInches th='Top Width' inches={60} />
					<ConverterInches th='Top Height' inches={18} />
					<ConverterInches th='Top Thickness' inches={1.5} />
				</tbody>
			</table>
			
			<h3>Square Table <small>- 30 x 60 inch</small></h3>
			<p><a href="https://www.forbesindustries.com/product/revolution-table-30-x-60-banquet/">Ref</a></p>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Height' inches={29} />
					<ConverterInches th='Top Width' inches={60} />
					<ConverterInches th='Top Height' inches={30} />
					<ConverterInches th='Top Top Thickness' inches={1.5} />
				</tbody>
			</table>
			
			
			
			
			<h2>Round Tables</h2>
			
			<h3>Round Table <small>- 30 inch</small> (Cocktail)</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Height' inches={42} />
					<ConverterInches th='Diameter' inches={30} />
					<ConverterInches th='Thickness' inches={1.75} />
				</tbody>
			</table>
			
			<h3>Round Table <small>- 60 inch</small></h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Height' inches={29} />
					<ConverterInches th='Diameter' inches={60} />
					<ConverterInches th='Thickness' inches={1.75} />
				</tbody>
			</table>
	
			<h3>Round Table <small>- 66 inch</small></h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Height' inches={29} />
					<ConverterInches th='Diameter' inches={66} />
					<ConverterInches th='Thickness' inches={1.75} />
				</tbody>
			</table>
	
			<h3>Round Table <small>- 72 inch</small></h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Height' inches={29} />
					<ConverterInches th='Diameter' inches={72} />
					<ConverterInches th='Thickness' inches={1.75} />
				</tbody>
			</table>
	
			<h3>Round & Rectangular Table Spacing for 60, 66, & 72 inches</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th='Edge to edge - 60 inch' inches={60} />
					<ConverterInches th='Edge to edge - 66 inch' inches={66} />
					<ConverterInches th='Edge to edge - 72 inch' inches={72} />
					<ConverterInches th='Chair to table' inches={18} />
					<ConverterInches th='Chair to chair (w/ Tables)' inches={24} />
					<ConverterInches th='Chair to chair (Theater set up)' inches={20} />
					<ConverterInches th='60 inch Table & chair (60 + 36)' inches={96} />
					<ConverterInches th='66 inch Table & chair (66 + 36)' inches={102} />
					<ConverterInches th='72 inch Table & chair (72 + 36)' inches={108} />
				</tbody>
			</table>
		
			
			<h3>Standard Podium</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Width" inches={26} />
					<ConverterInches th='Height' inches={47} />
				</tbody>
			</table>
			
			<h3>Stage Units</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterFeet th="Width (X)" feet={8} />
					<ConverterInches th="Height (Y)" inches={32} />
					<ConverterFeet th="Depth (Z)" feet={6} />
				</tbody>
			</table>
			
			<h3>Dance Floor</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Width" inches={34} />
					<ConverterInches th='Height' inches={34} />
				</tbody>
			</table>
			
			<h3>Portable Bar</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Width (X)" inches={96} /> 
					<ConverterInches th='Height (Y)' inches={48} />
					<ConverterInches th="Depth (Z)" inches={26} />
				</tbody>
			</table>
			
			<h3>Video Screens</h3>
			<table className="table mb-4">
				<thead>
					<tr>
						<th scope="col">Desc.</th>
						<th scope="col">Inches/Feet</th>
						<th scope="col">Meters</th>
						<th scope="col">Millimeters</th>
					</tr>
				</thead>
				<tbody>
					<ConverterInches th="Width" inches={75.9} />
					<ConverterInches th='Height' inches={43.5} />
				</tbody>
			</table>
	
		</div>
	</div>
	</main>
  );
}
export default Page;
