import {NavLink } from 'react-router-dom';

function Nav() {
	return (
	<div className="row navDesign justify-content-center">
		<ul className="nav nav-pills justify-content-center">
			
			<li className="nav-item">
				<NavLink activeClassName="active" exact to="/docs" className={`nav-link`}>API</NavLink>
			</li>
			<li className="nav-item">
				<NavLink activeClassName="active" to="/docs/specs" className={`nav-link`}>Specs</NavLink>
			</li>
		</ul>
	</div>
	);
}
export default Nav;
