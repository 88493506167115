function Page() {
  return (
   <>
  	<div className="container-fluid hero">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<p>Argus <span>Extended Reality</span></p>
				</div>
			</div>
		</div>
	</div>
	<main className="main-container container">
		<div className="row mt-4 justify-content-center">
			<div className="col-md-8">
				<h1>About Us</h1>
				<p><span className="argus-mark">Argus <abbr title="Extended Reality">XR</abbr> <sup>TM</sup></span> is a software company, providing extended reality solutions to enterprises.</p>
			</div>
		</div>	
	</main>
	</>
  );
}

export default Page;
