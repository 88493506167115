import RoomJson from '../json/Room.js';

const Room =(props) => {
	
	const rooms = RoomJson.[props.propertyId];
	
	return (
	<div className="form-group mb-4">
		<label htmlFor="room" className="form-label">Room:</label>
		<select id="room" value={props.room} onChange={(e) => props.setRoom(e.target.value)} className="form-select">
			{rooms &&
				rooms.map((value, i) => (
					<option key={i} value={value.name}>{value.name}</option>
				))
			}
		</select>
	</div>
)}
export default Room;