import React, {useState} from 'react';

// CSS
import cssForms from '../css/Forms.module.scss';

const ObjectSelect = (props) => {

	var val;
	var spacing = {x: 0, z: 0};
	var scale = {x: 1, y: 1, z: 1};
	var size = {x: 1, y: 1, z: 1};
	var rows = props.rows;
	var cols = props.cols;
	
	var nodes;
	
	const [displayName, setDisplayName] = useState("Cube");
		
	var handleChange = (e) => {
	
		val = e.target.value;
		
		var tableNodes = [
			{name: "Cushion", color: "#8D8D8D", texture: "none", visible: true,
				options: [
						{hex: "#8D8D8D", name: "Gray"},
						{hex: "#414141", name: "Black"},
						{hex: "#8d3e33", name: "Burgundy"}
				]
			},
			/*
			{name: "Frame", color: "#CACACA", texture: "none", visible: true,
				options: [
						{hex: "#CACACA", name: "Metal"},
			]},*/
			{name: "Cloth", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			},
			{name: "Vase", visible: true},
			{name: "Plates", visible: true},
			/*
			{name: "Napkins", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			},
			{name: "Glasses", visible: true},
			{name: "Utenstils", visible: true}
			*/
		];
		
		var tableNodesChiavari = [
			{name: "Frame", color: "#c04000", texture: "none", visible: true,
				options: [
						{hex: "#c04000", name: "Mahogany"},
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"}
			]},
			{name: "Cloth", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			},
			{name: "Cushion", color: "#8D8D8D", texture: "none", visible: true,
				options: [
						{hex: "#8D8D8D", name: "Gray"},
						{hex: "#414141", name: "Black"},
						{hex: "#8d3e33", name: "Burgundy"}
				]
			},
			{name: "Vase", visible: true},
			{name: "Plates", visible: true},
			/*
			{name: "Napkins", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			},
			{name: "Glasses", visible: true},
			{name: "Utenstils", visible: true}
			*/
		];
		
		var tableNodesCocktail = [
			{name: "Cloth", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			}
		];
		
		var tableNodesMeeting = [
			{name: "Cushion", color: "#8D8D8D", texture: "none", visible: true,
				options: [
						{hex: "#8D8D8D", name: "Gray"},
						{hex: "#414141", name: "Black"},
						{hex: "#8d3e33", name: "Burgundy"}
				]
			},
			
			{name: "Frame", color: "#CACACA", texture: "none", visible: true,
				options: [
						{hex: "#CACACA", name: "Metal"},
			]},
			{name: "Cloth", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			}
		];
		
		var tableNodesBanquet = [
			{name: "Frame", color: "#c04000", texture: "none", visible: true,
				options: [
						{hex: "#c04000", name: "Mahogany"},
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"}
			]},
			{name: "Cloth", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			},
			{name: "Cushion", color: "#8D8D8D", texture: "none", visible: true,
				options: [
						{hex: "#8D8D8D", name: "Gray"},
						{hex: "#414141", name: "Black"},
						{hex: "#8d3e33", name: "Burgundy"}
				]
			},
			{name: "Vase", visible: true},
			{name: "Plates", visible: true},
			/*
			{name: "Napkins", color: "#f3edd5", texture: "none", visible: true,
				options: [
						{hex: "#ffffff", name: "White"},
						{hex: "#414141", name: "Black"},
						{hex: "#f3edd5", name: "Cream"}
				]
			},
			{name: "Glasses", visible: true},
			{name: "Utenstils", visible: true}
			*/
		];
		
		switch(val) {
			case "table60in":
				
				setDisplayName("Table - 60in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 2.438, y: 1.0593, z: 2.438};
				nodes = tableNodes;
				rows = 1;
				cols = 1;
			break;

			case "table66in":
				
				setDisplayName("Table - 66in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 2.591, y: 1.0593, z: 2.591};
				nodes = tableNodes;
				rows = 1;
				cols = 1;
			break;

			case "table72in":
				
				setDisplayName("Table - 72in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 2.591, y: 1.0593, z: 2.591};
				nodes = tableNodes;
				rows = 1;
				cols = 1;
			break;
			
			case "table60inChiavari":
				
				setDisplayName("Table - 60in (Chiavari)");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 2.2983, y: 1.0593, z: 2.2983};
				nodes = tableNodesChiavari;
				rows = 1;
				cols = 1;
			break;

			case "table66inChiavari":
				
				setDisplayName("Table - 66in (Chiavari)");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 2.524, y: 1.0593, z: 2.524};
				nodes = tableNodesChiavari;
				rows = 1;
				cols = 1;
			break;

			case "table72inChiavari":
				
				setDisplayName("Table - 72in (Chiavari)");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 2.7543, y: 1.0593, z: 2.7543};
				nodes = tableNodesChiavari;
				rows = 1;
				cols = 1;
			break;
			
			case "table30inCocktail":
				
				setDisplayName("Cocktail Table - 30in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 0.9581, y: 1.4123, z: 0.9581};
				nodes = tableNodesCocktail;
				rows = 1;
				cols = 1;
			break;
			
			case "table18inMeeting":
				
				setDisplayName("Table - 18 x 60in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 1.524, y: 0.7373, z: 1.0392};
				nodes = tableNodesMeeting;
				rows = 1;
				cols = 1;
			break;

			case "table30inMeeting":
				
				setDisplayName("Table - 30 x 60in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 1.524, y: 0.7373, z: 1.3641};
				nodes = tableNodesMeeting;
				rows = 1;
				cols = 1;
			break;
			
			case "table18inBanquet":
				
				setDisplayName("Banquet Table - 18 x 60in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 1.524, y: 0.7373, z: 1.0392};
				nodes = tableNodesBanquet;
				rows = 1;
				cols = 1;
			break;

			case "table30inBanquet":
				
				setDisplayName("Banquet Table - 30 x 60in");
				spacing = {x: 0.6096, z: 0.6096};
				size = {x: 1.524, y: 0.7373, z: 1.3641};
				nodes = tableNodesBanquet;
				rows = 1;
				cols = 1;
			break;
			
			case "chairLancaster":
				
				setDisplayName("Lancaster Chair");
				spacing = {x: 0.5080, z: 0.5080};
				size = {x: 0.447, y: 0.9683, z: 0.6226};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Cushion", color: "#8D8D8D", texture: "none", visible: true, 
						options: [
							{hex: "#8D8D8D", name: "Gray"},
							{hex: "#414141", name: "Black"},
							{hex: "#8d3e33", name: "Burgundy"}
						]
					}/*,
					{name: "Frame", color: "#CACACA", texture: "none", visible: true,  
						options: [
							{hex: "#CACACA", name: "Metal"}
						]
					}*/
				];
			break;
			
			case "chairChiavari":
				
				setDisplayName("Chiavari Chair");
				spacing = {x: 0.5080, z: 0.5080};
				size = {x: 0.3905, y: 0.8880, z: 0.4558};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Cushion", color: "#8D8D8D", texture: "none", visible: true,  
						options: [
							{hex: "#8D8D8D", name: "Gray"},
							{hex: "#414141", name: "Black"},
							{hex: "#8d3e33", name: "Burgundy"}
						]
					},
					{name: "Frame", color: "#c04000", texture: "none", visible: true,  
						options: [
							{hex: "#c04000", name: "Mahogany"},
							{hex: "#ffffff", name: "White"},
							{hex: "#414141", name: "Black"}
						]
					}
				];
			break;
	
			case "portableBar":
				
				setDisplayName("Portable Bar");
				spacing = {x: 0, z: 0};
				size = {x: 2.4913, y: 1.2587, z: 0.7685};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Bar", color: "#000000", texture: "none", visible: true} 
				];
			break;
	
			case "videoScreen":
				
				setDisplayName("Video Screen");
				spacing = {x: 0, z: 0};
				size = {x: 1.928, y: 1.974, z: 0.3427};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Screen", color: "#000000", texture: "none", visible: true}
				];
			break;
	
			case "podium":
				
				setDisplayName("Podium");
				spacing = {x: 0, z: 0};
				size = {x: 0.6604, y: 1.194, z: 0.6604};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Podium", color: "#000000", texture: "none", visible: true}
				];
			break;
	
			case "stageUnit":
				
				setDisplayName("Stage Unit");
				spacing = {x: 0, z: 0};
				size = {x: 1, y: .8, z: 1};
				scale = {x: 1, y: .8, z: 1};
				rows = 4;
				cols = 6;
				
				nodes = [
					{name: "Stage Unit", color: "#000000", texture: "none", visible: true}
				];
			break;
	
			case "danceFloorUnit":
				
				setDisplayName("Dance Floor Unit");
				spacing = {x: 0, z: 0};
				size = {x: 1, y: .1, z: 1};
				scale = {x: 1, y: .1, z: 1};
				rows = 6;
				cols = 6;
				
				nodes = [
					{name: "Dance Floor Unit", color: "#000000", texture: "none", visible: true}
				];
			break;

			case "sphere":
				
				setDisplayName("Sphere");
				spacing = {x: 1, z: 1};
				size = {x: 1, y: 1, z: 1};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Sphere", color: "#1A40FF", texture: "none", visible: null,
						options: [
							{hex: "#f10909", name: "Red"},
							{hex: "#50D869", name: "Green"},
							{hex: "#1A40FF", name: "Blue"}
						]
					}
				];
			break;

			case "cube":
				
				setDisplayName("Cube");
				spacing = {x: 1, z: 1};
				size = {x: 1, y: 1, z: 1};
				rows = 1;
				cols = 1;
				
				nodes = [
					{name: "Cube", color: "#f10909", texture: "none", visible: null,
						options: [
							{hex: "#f10909", name: "Red"},
							{hex: "#50D869", name: "Green"},
							{hex: "#1A40FF", name: "Blue"}
						]
					}
				];
			break;

			default:
				
				setDisplayName("Cube");
				spacing = {x: 1, z: 1};
				size = {x: 1, y: 1, z: 1};
				
				nodes = [
					{name: "Cube", color: "#f10909", texture: "none", visible: null,
						options: [
							{hex: "#f10909", name: "Red"},
							{hex: "#50D869", name: "Green"},
							{hex: "#1A40FF", name: "Blue"}
						]
					}
				];
		}
		
		props.setSpacing(spacing);
		props.setScale(scale);
		props.setSize(size);
		props.setObjSelect(val);
		
		props.setRows(rows);
		props.setCols(cols);
		
		
		props.setNodes(nodes);
	}
	
	return (
	<div className={`container pt-2 ${cssForms.boxObjectSelect}`}>
		<div className="dropdown">
			<div className="row">
				<div className="col-4">
					<button className={`btn dropdown-toggle mb-1 ${cssForms.btnObjectSelect} ${cssForms.[props.objSelect]}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
					<div className={`dropdown-menu p-3 ${cssForms.dropdownMenu}`} aria-labelledby="dropdownMenuButton1">
						<div className={`${cssForms.containerObjects}`}>
							<h4 className={`${cssForms.objHeader}`}>Round Tables</h4>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable60in" checked={props.objSelect === "table60in"} value="table60in" onChange={handleChange} />
									<label className={`label ${cssForms.table60in}`} htmlFor="objectTable60in"><span>Table - 60in</span></label>
								</div>
								{/* 
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable66in" checked={props.objSelect === "table66in"} value="table66in" onChange={handleChange} />
									<label className={`label ${cssForms.table66in}`} htmlFor="objectTable66in"><span>Table - 66in</span></label>
								</div>
								*/}
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable72in" checked={props.objSelect === "table72in"} value="table72in" onChange={handleChange} />
									<label className={`label ${cssForms.table72in}`} htmlFor="objectTable72in"><span>Table - 72in</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable30in" checked={props.objSelect === "table30inCocktail"} value="table30inCocktail" onChange={handleChange} />
									<label className={`label ${cssForms.table30inCocktail}`} htmlFor="objectTable30in"><span>Cocktail Table - 30in</span></label>
								</div>
							</div>
							<h4 className={`mt-3 ${cssForms.objHeader}`}>Round Tables - Chiavari Chairs</h4>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable60inChiavari" checked={props.objSelect === "table60inChiavari"} value="table60inChiavari" onChange={handleChange} />
									<label className={`label ${cssForms.table60inChiavari}`} htmlFor="objectTable60inChiavari"><span>Table - 60in (Chiavari)</span></label>
								</div>
								{/* 
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable66inChiavari" checked={props.objSelect === "table66inChiavari"} value="table66inChiavari" onChange={handleChange} />
									<label className={`label ${cssForms.table66inChiavari}`} htmlFor="objectTable66inChiavari"><span>Table - 66in (Chiavari)</span></label>
								</div>
								*/}
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable72inChiavari" checked={props.objSelect === "table72inChiavari"} value="table72inChiavari" onChange={handleChange} />
									<label className={`label ${cssForms.table72inChiavari}`} htmlFor="objectTable72inChiavari"><span>Table - 72in (Chiavari)</span></label>
								</div>
							</div>
							<h4 className={`mt-3 ${cssForms.objHeader}`}>Meeting Tables</h4>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable18inMeeting" checked={props.objSelect === "table18inMeeting"} value="table18inMeeting" onChange={handleChange} />
									<label className={`label ${cssForms.table18inMeeting}`} htmlFor="objectTable18inMeeting"><span>Table - 18 x 60in</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable30inMeeting" checked={props.objSelect === "table30inMeeting"} value="table30inMeeting" onChange={handleChange} />
									<label className={`label ${cssForms.table30inMeeting}`} htmlFor="objectTable30inMeeting"><span>Table - 30 x 60in</span></label>
								</div>
							</div>
							
							<h4 className={`mt-3 ${cssForms.objHeader}`}>Banquet Tables</h4>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable18inBanquet" checked={props.objSelect === "table18inBanquet"} value="table18inBanquet" onChange={handleChange} />
									<label className={`label ${cssForms.table18inBanquet}`} htmlFor="objectTable18inBanquet"><span>Table - 18 x 60in</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectTable30inBanquet" checked={props.objSelect === "table30inBanquet"} value="table30inBanquet" onChange={handleChange} />
									<label className={`label ${cssForms.table30inBanquet}`} htmlFor="objectTable30inBanquet"><span>Table - 30 x 60in</span></label>
								</div>
							</div>
							<hr />
							<h4 className={`mt-2 ${cssForms.objHeader}`}>Chairs</h4>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="chairLancaster" checked={props.objSelect === "chairLancaster"} value="chairLancaster" onChange={handleChange} />
									<label className={`label ${cssForms.chairLancaster}`} htmlFor="chairLancaster"><span>Lancaster Chair</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="chairChiavari" checked={props.objSelect === "chairChiavari"} value="chairChiavari" onChange={handleChange} />
									<label className={`label ${cssForms.chairChiavari}`} htmlFor="chairChiavari"><span>Chiavari Chair</span></label>
								</div>
							</div>
							<hr />
							<h4 className={`mt-2 ${cssForms.objHeader}`}>Other</h4>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="portableBar" checked={props.objSelect === "portableBar"} value="portableBar" onChange={handleChange} />
									<label className={`label ${cssForms.portableBar}`} htmlFor="portableBar"><span>Portable Bar</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="danceFloorUnit" checked={props.objSelect === "danceFloorUnit"} value="danceFloorUnit" onChange={handleChange} />
									<label className={`label ${cssForms.danceFloorUnit}`} htmlFor="danceFloorUnit"><span>Dance Floor Unit</span></label>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-4">
									<input type="radio" name="objSelect" id="stageUnit" checked={props.objSelect === "stageUnit"} value="stageUnit" onChange={handleChange} />
									<label className={`label ${cssForms.stageUnit}`} htmlFor="stageUnit"><span>Stage Unit</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="podium" checked={props.objSelect === "podium"} value="podium" onChange={handleChange} />
									<label className={`label ${cssForms.podium}`} htmlFor="podium"><span>Podium</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="videoScreen" checked={props.objSelect === "videoScreen"} value="videoScreen" onChange={handleChange} />
									<label className={`label ${cssForms.videoScreen}`} htmlFor="videoScreen"><span>Video Screen</span></label>
								</div>
							</div>
							<hr />
							<h4 className={`mt-2 ${cssForms.objHeader}`}>Primitives</h4>
							<div className="row">
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectCube" checked={props.objSelect === "cube"} value="cube" onChange={handleChange} />
									<label className={`label ${cssForms.cube}`} htmlFor="objectCube"><span>Cube</span></label>
								</div>
								<div className="col-4">
									<input type="radio" name="objSelect" id="objectSphere" checked={props.objSelect === "sphere"} value="sphere" onChange={handleChange} />
									<label className={`label ${cssForms.sphere}`} htmlFor="objectSphere"><span>Sphere</span></label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-8">
					<p className="mb-1">{displayName}</p>
				</div>
			</div>
		</div>
	</div>	
	)
}
export default ObjectSelect;
