import React, {useState}  from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, addDoc, collection} from 'firebase/firestore';

// Components
import Alert from '../components/cards/CardAlert';
import RoomSelect from '../components/controls/RoomSelect.js';

function Page() {
	
	const db = getFirestore(fire);
	let { propertyId } = useParams();
	let history = useHistory();
	
	const [name, setName] = useState("");
	const [desc, setDesc] = useState("");
	const [room, setRoom] = useState("");
	const [type, setType] = useState("");
	const [guest, setGuest] = useState(0);
	const [alertState, setAlertState] = useState("");

	const handleSubmit = async (e) => {
	
		e.preventDefault();
		
		let img = "";
		
		try {
			
			switch(type) {
				case "Wedding":
					img = "/img/events/karl-and-gina.png";
				break;

				case "Business meeting":
					img = "/img/events/business-meeting.png";
				break;

				case "Bar mitzvah":
					img = "/img/events/karl-and-gina.png";
				break;

				case "Bat mitzvah":
					img = "/img/events/karl-and-gina.png";
				break;	
				
				case "Birthday party":
					img = "/img/events/karl-and-gina.png";
				break;
			
				default:
					img = "/img/events/karl-and-gina.png";
			}
			
			await addDoc(collection(db, "properties", propertyId,  "events"), {
				desc: desc,
				img: img,
				name: name,
				scenes: [],
				guest: guest,
				type: type,
				time: new Date()
			});
			
			history.push("/event-success");
		}
		
		catch (e) {
			
			setAlertState("error");
		}
	}
	
	return (
	<main className="main-container container mt-3">
	<div className="row mt-4 justify-content-center">
		<div className="col-8">
		
			{(alertState === "success") &&
				<Alert type="success">
					Success! View new event from <Link exact to="/dashboard">Dashboard</Link>.
				</Alert>
			}
			
			{(alertState === "warning") &&
				<Alert type="warning">
					Warning!
				</Alert>
			}
			
			{(alertState === "danger") &&
				<Alert type="danger">
					Error!
				</Alert>
			}
			
			<h1>New Event</h1>
			
			<form onSubmit={handleSubmit}>
				<div className="form-group mb-4">
					<label htmlFor="name" className="form-label">Name:</label>
					<input id="name" className="form-control" type="text" value={name} onChange={({target}) => setName(target.value)} />
				</div>
				<div className="form-group mb-4">
					<label htmlFor="desc" className="form-label">Description:</label>
					<input id="desc" className="form-control" type="text" value={desc} onChange={({target}) => setDesc(target.value)} />
				</div>
				<div className="form-group mb-4">
					<RoomSelect propertyId={propertyId} room={room} setRoom={setRoom} />
				</div>
				<div className="row mb-4">
					<label htmlFor="type" className="col-sm-3 col-form-label">Type:</label>
					<div className="col-sm-9">
						<select id="type" value={type} onChange={(e) => setType(e.value)} className="form-select" style={{width: "200px"}}>
							<option value="Wedding">Wedding</option>
							<option value="Meeting">Business meeting</option>
							<option value="Bar mitzvah">Bar mitzvah</option>
							<option value="Bat mitzvah">Bat mitzvah</option>
							<option value="Birthday party">Birthday party</option>
						</select>
					</div>
				</div>
				<div className="row mb-4">
					<label htmlFor="guest" className="col-6 form-label">No. of guest:</label>
					<div className="col-6">
						<input id="guest" className="form-control" style={{width: "80px"}} type="number" value={guest} size="4"
						onChange={ ({target}) => setGuest(target.value) } 
						 />
					</div>
				</div>
				<hr />
				<button type="button" onClick={() => history.goBack()} className="btn btn-primary mt-3">Cancel</button>
				<button type="submit" value="submit" className="btn btn-primary mt-3 ms-3">Submit</button>
			</form>
			
		</div>
	</div>
	</main>
	);
}
export default Page;
