import React from 'react';

const Group =(props) => {
	
	var groupChange = (e) => {
		let val = e.target.value;
		props.setGroupSelect(val);
	}
	return (
	<>
	<div className="mb-3">
		<select value={props.group} onChange={groupChange} className="form-select form-select-sm">
			{props.options &&
				props.options.map((value, i) => (
					<option key={i} value={value.name}>{value.name}</option>
				))
			}
		</select>
	</div>
	</>	
)}
export default Group;