import React, { useState, useEffect} from 'react';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, collection, getDocs } from 'firebase/firestore';
// getDoc, doc

// Components
import NavPlaygrounds from '../components/navs/NavPlaygrounds';


function Home() {

	const db = getFirestore(fire);
	
	const [scenes, setScenes] = useState([]);
 	const [events, setEvents] = useState([]);
 	
	useEffect(() => {
		
		// Events then scenes
		function getEvents(collName) {
			
			return new Promise((resolve, reject) => {
			
				const col = collection(db, collName);
				const snapshot = getDocs(col);
			
				resolve(snapshot);
				reject(new Error("User not Logged in"));
			});
		}
		
		// Just scences
		function getScenes(event) {
		
			return new Promise((resolve, reject) => {
			
				const col = collection(db, 'events', event, "scenes");
				const snapshot = getDocs(col);
				
				resolve(snapshot);
			});
		
		}
		
		// INIT
		function init() {
		
			getEvents('events').then(snapshot => {
				
		 		const eventsAll = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
		 		
		 		eventsAll.map(event => {
		 		
					getScenes(event.id).then(scene => {
						
						const scenesAll = scene.docs.map(doc => ({id: doc.id, ...doc.data()}));
						event.sceneList = scenesAll;
						setScenes(scenesAll); // purpose is to force rerender. Without this scenes don't show in UI
					});
					
					return null
		 		});
		 			 		
		 		setEvents(eventsAll);
			});
			
		}
		
		init()
	
  	}, [db])
  	
	return (
	<main className="main-container container mt-3">
		<NavPlaygrounds />
		<h1>Events > Scenes</h1>
		
		<hr />
		
		<h2>EVENTS > SCENES</h2>
	
		<div className="mb-3">
			<h2><strong> Firestore: </strong> Events Then Scenes</h2>
			
			<h4>Events:</h4>
			{events.map((e, i) => (
				<li key={i}>Event: {e.name}
					<ul>
						{e.sceneList && e.sceneList.map((s, i) => (
							<li key={i}>Scenes: {s.name}</li>
						))}
					</ul>
				</li>
			))}
			
			<hr />
			
			<h4>Scenes:</h4>
			{scenes.map((e, i) => (
				<li key={i}>Scene: {e.name}
					
				</li>
			))}
			
			
			
		</div>
		
		 {/* 
		<div className="mb-3">
			<h2>Scenes</h2>
			{scenes.map((e, i) => (
				<li key={i}>scene: {e.name}</li>
			))}
		</div>
		*/}
		
		
	</main>
  );
}

export default Home;
