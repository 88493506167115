const Visibility = (props) => {

 	var handleChange = (e) => {
		
		var checked = !props.node.visible
		
		props.setNodes(
			prevState => {
				return prevState.map(item => item.name === props.node.name ? {...item, visible : checked} : item);
			}
		);
	}
 	
	return (
	<div className="form-check">
		{/* <p>node.visible: {props.node.visible.toString()}</p> */}
		<input className="form-check-input"
		onChange={(e) => {handleChange(e)}}
		type="checkbox" id="visibilityCheck" checked={props.node.visible} />
		<label className="form-check-label" htmlFor="visibilityCheck">Visible</label>
	</div>
	)
}
export default Visibility;