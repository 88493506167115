import styles from './Cards.module.scss';

function Card(props) {
	return (
	<div className={`card ${styles.card} ${props.className} mb-3`} style={props.style}>
		{props.img &&
		<div className={`${styles.img}`}>
			<img src={props.img} className="card-img-top" alt="Visualization of event goes here." />
		</div>
		}
		<div className="card-body">
			<time className="small">
				{props.time && 
				`${props.time.toDate().toDateString()}, ${props.time.toDate().getHours()}:${props.time.toDate().getMinutes()}:${props.time.toDate().getSeconds()}`
				}  
			</time>	
			<h3 className={`card-title ${styles.title}`}>{props.heading}</h3>
			{props.children}
		</div>
	</div>
	)
}
export default Card