// Components
import Card from '../components/cards/Card';
import NavDocs from '../components/navs/NavDocs';

function Page() {
	return (
	<main className="main-container container mt-3">
	<NavDocs />
	<div className="row">
		<div className="col-12">
			<h1>API</h1>
			
			<Card heading="Rooms">
				<h3>Groups</h3>
				<table className="table mb-4">
					<thead>
						<tr>
							<th scope="col">useState Hooks</th>
							<th scope="col">Desc.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">[groups, setGroups]</th>
							<td>Array of three.js Group objects, group names, and group position coordinates.</td>
						</tr>
						<tr>
							<th scope="row">[groupName, setGroupName]</th>
							<td>Holds value of Group name text input field</td>
						</tr>
						<tr>
							<th scope="row">[groupSelect, setGroupSelect]</th>
							<td>Holds current group chosen in group select pulldown</td>
						</tr>
						<tr>
							<th scope="row">[groupPos, setGroupPos]</th>
							<td>Object of x, y,and z coordinates of group</td>
						</tr>
					</tbody>
				</table>
				
				<table className="table mb-4">
					<thead>
						<tr>
							<th scope="col">Functions</th>
							<th scope="col">Desc.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">handleAddGroup()</th>
							<td>Event Handler method for adding new groups</td>
						</tr>
						<tr>
							<th scope="row">handleDeleteGroup()</th>
							<td>Event Handler for deleting groups</td>
						</tr>
						<tr>
							<th scope="row">addGroupAsync()</th>
							<td>Add group data to Firestore</td>
						</tr>
					</tbody>
				</table>
			</Card>
			<Card heading="Authentication">
				<h3>Groups</h3>
				<table className="table mb-4">
					<thead>
						<tr>
							<th scope="col">Code</th>
							<th scope="col">Desc.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">&#123;auth.email&#125;</th>
							<td>Displays signed-in user's email from useAuth hook (/auth/use-auth.js).</td>
						</tr>
						<tr>
							<th scope="row">&#123;auth.user&#125;</th>
							<td>Displays signed-in user's ID from useAuth hook (/auth/use-auth.js).</td>
						</tr>
						<tr>
							<th scope="row">&#123;user.firstName&#125;</th>
							<td>Displays signed-in user First Name from UserContext object.</td>
						</tr>
					</tbody>
				</table>
			</Card>
		</div>
	</div>
	</main>
  );
}
export default Page;
