import React, {useState, useEffect}  from 'react';
import {useParams, useHistory} from 'react-router-dom';

// Firebase
import fire from '../config/fire-config'
import {getFirestore, getDoc, doc} from 'firebase/firestore';

// Components
import RoomSelect from '../components/controls/RoomSelect.js';

function Page() {
	
	const db = getFirestore(fire);
	const history = useHistory();
	const {propertyId} = useParams();
	const {eventId} = useParams();
	const {sceneId} = useParams();
	const [name, setName] = useState("");
	const [room, setRoom] = useState("");
	
	const handleSubmit = async (e) => {
		e.preventDefault();
	}
	
	useEffect(() => {
	
		async function getScene(db) {
	
			const colEvents = doc(db, "properties", propertyId, 'events', eventId, 'scenes', sceneId);
			const snapshot = await getDoc(colEvents);
			
			if (snapshot.exists()) {
				setName(snapshot.data().name);
			}
		}	
		getScene(db);
		
	}, [db, propertyId, eventId, sceneId])
	
	return (
	<main className="main-container container">
	<div className="row mt-3 justify-content-center">
		<div className="col-6">
			<h1>Edit Scene</h1>
			<form onSubmit={handleSubmit}>
				<div className="form-group mb-4">
					<label htmlFor="name" className="form-label">Name:</label>
					<input id="name" className="form-control" type="text" value={name} onChange={({target}) => setName(target.value)} />
				</div>
				<RoomSelect propertyId={propertyId} room={room} setRoom={setRoom} />
				<hr />
				<button type="button" onClick={() => history.goBack()} className="btn btn-primary mt-3">Cancel</button>
				<button type="submit" className="btn btn-primary mt-3 ms-3">Save</button>
			</form>
		</div>
	</div>
	</main>
	);
}
export default Page;
